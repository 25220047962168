import React, {useEffect, useState} from "react";

const EventsPage = () => {
    const [data, setData] = useState({ events: [] });

    const isInThePast = (date) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        return date < today;
    };

    const getData = () => {
        fetch('events.json',{
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(function(response){
            console.log(response)
            return response.json();
        })
        .then(function(myJson) {
            setData(myJson);
        });
    };

    useEffect(() => {
        getData();
    },[]);

    return (
        <main>
            <section className="recentEventsBlock pt-7 pb-7 pt-md-12 pb-md-12 pt-lg-15 pb-lg-18 pt-xl-20 pb-xl-24">
                <div className="container">
                    <header className="teeSideHead mb-6 mb-lg-11">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-8">
                                <h1 className=" mb-0">Kalendár podujatí</h1>
                            </div>
                        </div>
                    </header>
                    {data.events.filter((e) => !isInThePast(new Date(e.dateRaw))).map((event) => {
                        return (
                            <>
                                <article className="eveProColumn d-sm-flex overflow-hidden">
                                    <time dateTime={event.dateRaw}
                                          className="epcTimeStamp d-block flex-shrink-0 pt-6 pt-sm-0 mt-sm-n1 mr-2 mr-md-4 mr-lg-10 text-uppercase">
                                        <span className="textDate text-brown">{event.dd}</span>
                                        <span className="textMonth fwMedium text-secondary">{event.mm}</span>
                                        <span className="w-100 d-block textDay font-weight-normal fontSerif mt-1">{event.yy}</span>
                                    </time>
                                    <div className="descrWrap pt-1">
                                        <strong className="tpHeadingTitle text-uppercase font-weight-normal d-block mb-3">
                                            {event.category}
                                        </strong>
                                        <h2 className="mb-3">
                                            {event.title}
                                        </h2>
                                        <div className="mb-3 mb-md-6">
                                            {event.description}
                                        </div>
                                    </div>
                                </article>
                                <hr className="my-4 my-md-8" />
                            </>
                        );
                    })}
                </div>
            </section>
        </main>
    );
};

export default EventsPage;