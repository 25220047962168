import React from "react";
import Blog from "../../components/blog/blog";

const blog = {
    title: 'Staviteľ Juraj Hudec',
    subTitle: '4. kapitola - Staviteľ Juraj Hudec (21. 4. 1858 – 18. 12. 1920)',
    eyeCatch: 'Staviteľ Juraj Hudec (21. 4. 1858 – 18. 12. 1920)',
    chapters: [
        'Staviteľ Juraj Hudec sa narodil 21. apríla 1858 v Dolnej Mičinej v kaštieli Benických v mlynárskej rodine Michala Hudeca a jeho prvej manželky Johany Kmeťovej. Počas otcovho pôsobenia v Očovej tam absolvoval ľudovú školu, potom gymnázium v Lučenci. V roku 1891 Juraj už pôsobí v Banskej Bystrici, jeho prvým vážnym zamestnávateľom bol stavebný podnikateľ Róbert Wünsch. Vlastnil firmy v Budapešti, Banskej Bystrici a Košiciach – bol priekopníkom nových stavebných materiálov a technológií (hlavne udomácnenia cementu a železobetónu). V Budapešti sa stal realizátorom výkopových a stavebných prác druhej podzemnej dráhy na svete (dodávateľom strojovej výbavy bola firma Siemens). Bola odovzdaná verejnosti 2. mája 1896.',
        'Medzi jeho majstrov patril aj Juraj Hudec, bol to dôležitý medzník jeho kariéry. Po návrate domov, stále pod hlavičkou Wünschovej firmy, viedol opravy bystrických historických budov, ich prestavby, ale aj výstavby. Ako dozor mestského stavebného úradu zohral dôležitú úlohu pri realizácii mestskej elektrárne, vodovodu, kanalizácie a iných technických novôt. Stal sa hospodárskym inšpektorom mesta, bol členom obchodnej a priemyselnej komory, členom turistického spolku (Uhorská turistická spoločnosť) a ako veriaci evanjelik aj funkcionárom evanjelického cirkevného zboru mesta. Do roku 1900 býval dva roky vo Wünschovej vile pri ceste smerom na Slovenskú Ľupču ',
        'V roku 1898 sa zaevidoval ako samostatný podnikateľ a v roku 1902 zakladá firmu (Hugyecz – Rosenauer) spolu s inžinierom Ľudovítom Rosenauerom (pôsobila do roku 1920). Mala  rozsiahle prevádzkové budovy, betonársku, stolársku, zámočnícku dielňu, sklady a prenajatú tehelňu.',
        'Jeho pracovný pobyt v Budapešti mal veľký prínos pre ďalšie roky jeho činnosti. Zoznámil sa s viacerými čelnými architektmi, s ktorými sa pracovne stýkal aj v ďalších dvoch desaťročiach. Bol prítomný pri vytýčení novej ulice mesta smerom na severovýchod – dnes Skuteckého ulica. V rámci jej výstavby bol staviteľom piatich monumentálnych budov a po roku 1902 postavil tri budovy podľa vlastných projektov, medzi nimi budovu, v ktorej sa nachádzame.',
        'Roky 1. svetovej vojny a nasledujúce roky boli obdobím rýchleho úpadku finančného blahobytu, úspechu, ako i spoločenského postavenia pracovitého staviteľa. Exekúcia majetku v prvých rokoch ČSR, chýbajúca podpora vzdialeného najstaršieho syna a podlomené zdravie počas krátkej doby zapríčinili jeho úmrtie. Dňa 18. 12. 1920 zomrel 62-ročný.',
    ]
};

const Chapter4Page = () => {
    return (
        <Blog data={blog} />
    );
};

export default Chapter4Page;