import React from "react";
import fpu from '../../images/supporters/FPU.jpg';
import bb from '../../images/supporters/bb_city.png';

const Supporters = () => {
    return (
        <section className="newsBlock pt-7 pb-2 pt-md-10 pb-md-6 pt-lg-14 pb-lg-10 pt-xl-21 pb-xl-16">
            <div className="container">
                <header className="topHeadingHead text-center mb-7 mb-lg-12">
                    <h2 className="mb-13">Podporili nás</h2>
                </header>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-3">
                        <img src={fpu}  alt="Logo fondu na podporu umenia"/>
                    </div>
                    <div className="col-12 col-md-3">
                        <img src={bb}  alt="Logo fondu na podporu umenia"/>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default Supporters;