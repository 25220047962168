import React from "react";
import Blog from "../../components/blog/blog";

const blog = {
    title: 'Životné cesty L. E. Hudeca',
    subTitle: '8. Kapitola - Životné cesty L. E. Hudeca',
    eyeCatch: 'Životné cesty L. E. Hudeca',
    chapters: [
        'Ladislav Hudec sa narodil v rodine, v ktorej vernosť k rodinnej komunite, k rodnému mestu, rodovému náboženstvu (protestantizmu evanjelickej cirkvi a. v.), ako aj k zdedenej profesii boli hlboko zakorenené a vážené. Ladislav Hudec ich nosil v sebe a iba udalosti sveta zapríčinili, že jeho vernosť k rodnému mestu mu spôsobila hlavne utrpenie z odlúčenosti, lebo vyše dvoch tretín svojho života prežil na iných kontinentoch a na cestách.',
        'Z prvých študentských ciest v roku 1910 a 1914 sa ešte mohol vrátiť, ale tie ďalšie už neboli dobrovoľné a nebolo ani návratu. V 1. svetovej vojne bol zajatý na poľskom území v roku 1916 a odvezený do sibírskych zajateckých táborov. Vďaka vlastnej statočnosti a mnohostranným jazykovým i odborným znalostiam sa dostal sám z Ruska cez zamrznutý Amur do Číny – v novembri 1918 do Šanghaja. Od začiatku tam pracoval ako architekt po dobu skoro 30 rokov. Väčšina jeho ďalších ciest viedla na západ – do Európy, neskôr aj do Spojených štátov a Kanady. Loďou, vlakom, lietadlom.',
        'Boli to hlavne cesty poznávacie, za poznávaním novej architektúry západnej civilizácie, najnovších technických vymožeností, ktoré potom uplatňoval pri realizácii svojich stavieb v Šanghaji (napr. technológia zakladania prvého mrakodrapu v Ázii – Park Hotel). Od začiatku svojich ciest zaznamenával svoje zážitky kresbou, fotografovaním, neskôr i filmovaním. Počas letných mesiacov unikala rodina pred horúčavami Šanghaja do Európy alebo do Japonska.',
        'V roku 1939, keď pomery v Šanghaji nepriali architektonickej práci, bola rodina celý rok na cestách a do novembra obišli celú zemeguľu. O osem rokov sa tajne rozlúčili so Šanghajom a po ročnej zastávke v Európe (Lugano a Rím) sa usadili v USA, v Berkeley (Kalifornia). Po jeho smrti v tomto meste v roku 1958 boli jeho pozostatky prevezené do rodinnej krypty v Banskej Bystrici 12. 8. 1970. Tým sa kruh uzavrel.',
        '8. január 1893 Α (písmeno alpha) Banská Bystrica: Narodenie Ladislava Eduarda Hudeca',
        '1906 – 1909: Počas gymnaziálnych štúdií mladý Ladislav spoznáva pamiatky okolia svojho rodiska a Slovenska. Chodieva s otcom po jeho stavbách. Fotografuje okolie Banskej Bystrice, Spiša a Oravy.',
        '1910: Po maturite odchádza na prvú veľkú cestu loďou z Budapešti spolu so spolužiakom Júliusom Petrikovičom a prvým slovenským moreplavcom – kapitánom Jurajom Thurzom – na lodi Grog Tisza István. Plavia sa po Dunaji po Čierne more, ďalej cez Bospor, Istanbul, Jadran, Gibraltár, západné pobrežie Európy až po Rotterdam. Domov sa vracajú vlakom cez Berlín.',
        '1910 – 1914: Banská Bystrica má s Budapešťou vlakové spojenie od roku 1873. Ladislav ho využíva počas štúdia architektúry na Technickej univerzite v Budapešti.',
        '1911 – 1913: Ako vysokoškolák cestuje v lete po Slovensku. Pre profesora Luxa zameriava Starý zámok v Banskej Štiavnici a hrad v Slovenskej Ľupči.',
        '1913: Budapeštianska technická univerzita vedie svojich študentov každý rok na zahraničné exkurzie. V roku 1913 je Ladislav v Norimbergu a v ďalších nemeckých mestách.',
        '1914: Po skončení vysokoškolského štúdia ide na poznávaciu cestu do Talianska. Po vypuknutí 1. svetovej vojny musí cestu prerušiť a vracia sa domov.',
        '1914 – 1916: Na začiatku 1. svetovej vojny, po výcviku v Debrecíne, je odvelený na front do Poľska.',
        '1916 – 1918: Počas zajatia sú zajatci presúvaní cez Kyjev, Moskvu, Chabarovsk do Krasnej Rečky.',
        '1918: Počas presunu zajatcov sa mu podarí utiecť zo zajatia a cez Chabarovsk, Hilok, Charbin a zamrznutý Amur sa dostáva na územie Číny.',
        'November 1918 Šanghaj: Začiatkom novembra 1918 L. E. Hudec prichádza do Šanghaja.',
        '1921: Uskutoční cestu do Európy, počas ktorej navštívi Budapešť a aj rodné mesto.',
        '1922: Po sobáši s Gizelou Meyer cestuje cez Peking k Veľkému čínskemu múru.',
        '1927 – 1928 Európa: V tomto období cestuje do Európy, kde navštívi Mníchov (čerpá inšpirácie pre projekt veľkého pivovaru v Šanghaji), Prahu, Banskú Štiavnicu a Banskú Bystricu. V Mníchove sa mu narodila dcéra Alessa.',
        '1928: Návšteva Pekingu',
        '1906 – 1910 Manhattan, New York, USA: Poznávacia cesta do New Yorku a hľadanie inšpirácií pri budove Empire State Building.',
        '1932, 1935 – 1941 Karuizawa, Japonsko: Návštevy priateľa, architekta Antonia Reymonda, v japonskej Karuizawe počas leta.',
        '1931 – 1935: Cesty do Európy',
        '1939: Cesty loďou: Veľká cesta okolo sveta. Loďou Scheinhorse cez Suezský prieplav do Európy. V Budapešti obdrží maďarský pas. V New Yorku navštívi Expo 1939, „Dawn of a New Day“. V Seattli si kúpi nový automobil (Ford Deluxe). V kanadskej Viktórii navštívi synov Martina a Thea a v novembri sa vracia späť loďou z Kanady do Šanghaja.',
        '1947 – 1948: Odchod do Európy: Nútený odchod zo Šanghaja loďou cez Hongkong, Manilu, Singapur, Kolombo, Suezský prieplav, Neapol, Livorno, Janov s konečnou zástavkou v švajčiarskom Lugane, kde s rodinou žije do mája 1948. Často cestuje do Ríma v Taliansku.',
        '1948: Odchod do USA: Po ročnom pobyte v Európe sa definitívne presťahuje do USA. Loďou Prezident Monroe cestuje do New Yorku a usadí sa v Berkeley v zátoke Golden Gate.',
        '1953 Generálne odpustky: Počas cesty do Európy sa zúčastňuje na archeologickom výskume v Bazilike sv. Petra vo Vatikáne. Na audiencii u pápeža Pia XII. obdrží generálne odpustky.',
        '1954 – 1957: Európa a Kanada: Viackrát navštívi Európu (Nemecko, Švajčiarsko, Švédsko) a Viktória (Kanada).',
        '26. októbra 1958 Ω Berkeley, Kalifornia, USA: Architekt Ladislav Eduard Hudec zomiera na srdcový infarkt v Berkeley.',
        '1970: Posledná rozlúčka v Banskej Bystrici: 12. 8. 1970 – posledná rozlúčka s L. E. Hudecom pri ukladaní urny na evanjelickom cintoríne v rodnom meste.'
    ]
};

const HudecLifeRoadPage = () => {
    return (
        <Blog data={blog} />
    );
};

export default HudecLifeRoadPage;