import React from "react";
import conferenceRoom from '../images/spaces/title.jpg'
import wedding from '../images/spaces/wedding.jpg'
import expo from '../images/spaces/expo.jpg'

const SpaceRentalPage = () => {
    return (
        <main>
            <header className="mainHeadingHead position-relative bgCover w-100 d-flex text-white"
                    style={{ backgroundImage: `url(${conferenceRoom})` }}>
                <div className="mhhAlignHolder d-flex w-100 align-items-center position-relative">
                    <div className="container py-6">
                        <h1 className="text-white mb-0">Prenájom priestoru</h1>
                    </div>
                </div>
            </header>
            <article className="specialIntroAside pt-6 pb-2 pt-md-8 pb-md-8 pt-lg-13 pb-lg-10 pt-xl-21 pb-xl-15">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-8 offset-md-2">
                            <div className="extraWrap px-lg-5">
                                <h2 className="mb-5">Jedinečný priestor<br /> pre Vašu svadbu, oslavu či konferenciu</h2>
                                <p className="mb-6">
                                    Naše priestory ponúkajú možnosti realizácie rôznych kultúrno – spoločenských podujatí ako
                                    koncerty, vernisáže, konferencie, svadby či firemné večierky.
                                </p>
                                <p className="mb-6">
                                    K dispozícii sú parkovacie miesta v areáli, bezbariérový prístup, osobný výťah, kuchyňa s
                                    kompletným vybavením, klimatizácia a vzduchotechnika, projektor či zabudovaná audio technika
                                    pre hovorené slovo.
                                </p>
                                <p className="mb-6">
                                    Kapacita<br />
                                    Koncert, konferencia – 200 miest na sedenie<br />
                                    Svadba, oslava, firemný večierok – 100 ľudí
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 col-lg-6 grayscaled">
                            <iframe style={{ width: '100%', height: '300px' }} src="https://www.youtube.com/embed/1JJ_RIVpIoQ" />
                        </div>
                    </div>
                </div>
            </article>
            <section
                className="upcomingEventsBlock bg-grayf8f8f8 hasBdr text-center pt-7 pb-3 pt-md-10 pb-md-5 pt-xl-16 pb-xl-8 ">
                <div className="container">
                    <div className="row mx-xl-n4 justify-content-center">
                        <div className="col-12 col-md-6 col-lg-4 px-xl-4">
                            <article
                                className="upcEveColumn border position-relative py-8 px-4 mb-6 mb-xl-8 mx-auto mx-md-0 bg-white text-gray777 h-400">
                                <div className="imgHolder mb-5 overflow-hidden">
                                    <img src={conferenceRoom} className="img-fluid w-100"
                                         alt="image description" />
                                </div>
                                <h2>
                                    Konferencia
                                </h2>
                                <p className="mb-5">
                                    Pripravte Vašu konferenciu, workshop či prednášku v našich podkrovných priestoroch s moderným vybavením
                                </p>
                            </article>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 px-xl-4">
                            <article
                                className="upcEveColumn border position-relative py-8 px-4 mb-6 mb-xl-8 mx-auto mx-md-0 bg-white text-gray777 h-400">
                                <div className="imgHolder mb-5 overflow-hidden">
                                    <img src={wedding} className="img-fluid w-100"
                                         alt="image description" />
                                </div>
                                <h2>
                                    Svadba
                                </h2>
                                <p className="mb-5">
                                    Unikátny priestor v historickej budove pre Váš veľký svadobný deň, večierok alebo oslavu (foto / eventia)
                                </p>
                            </article>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 px-xl-4">
                            <article
                                className="upcEveColumn border position-relative py-8 px-4 mb-6 mb-xl-8 mx-auto mx-md-0 bg-white text-gray777 h-400">
                                <div className="imgHolder mb-5 overflow-hidden">
                                    <img src={expo} className="img-fluid w-100"
                                         alt="image description" />
                                </div>
                                <h2>
                                    Predstavenie
                                </h2>
                                <p className="mb-5">
                                    Náš priestor je vhodný nie len pre koncerty ( vážna hudba, jazz, akustická hudba a pod.) ale aj pre tanečné či
                                    divadelné predstavenia
                                </p>
                            </article>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default SpaceRentalPage;