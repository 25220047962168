import React from "react";
import Blog from "../../components/blog/blog";

const blog = {
    title: 'Diela L. E. Hudeca',
    subTitle: '15. kapitola - Spoznávanie života a diela architekta L. E. Hudeca',
    chapters: [
        'Architekt L. E. Hudec sa svojím priam neuveriteľným príbehom postupne dostáva do pozornosti a záujmu architektonickej i občianskej verejnosti.',
        'Profesor Zheng Shiling z Tongji University inicioval prvotný výskum Hudecovej tvorby koncom 20. storočia. Na základe ďalších prác zahraničných bádateľov a ich akademických aktivít vzťahujúcim sa k tvorbe L. E. Hudeca sa svet dozvedel o osobnosti banskobystrického rodáka: Kanaďanka Leonor Hierkamp doktorandskou prácou na Washingtonskej univerzite, Luca Poncellini  doktorandskou prácou na univerzite v Turíne, Austrálčanka Anne Warr, ako aj Edward Denison pôsobiaci na The Bartlett School of Architecture (UCL London). Na Slovensku sa architekt L. E. Hudec dostal do pozornosti vďaka prvým informáciám od Gejzu Balašu. Rok 2008 bol „Rokom Hudeca“ v Šanghaji z iniciatívy šanghajskej vlády a Hlavného konzulátu Maďarska v Šanghaji. V tom istom roku sa uskutočnila jeho prvá výstava na Slovensku v Štátnej vedeckej knižnici v Banskej Bystrici zásluhou architekta Štefana Šlachtu, v tom čase rektora VŠVU a predsedu Spolku architektov Slovenska (SAS) v Bratislave s fotografiami Mariana Moncmanna. Na svetovej výstave EXPO 2010 v Šanghaji sa konala premiéra dokumentárneho filmu režiséra Ladislava Kaboša Muž, ktorý zmenil tvár Šanghaja, ktorý v ďalšom roku začali premietať aj v našich kinách a na televíznych obrazovkách.',
        'Dnes je už zrejmé, že hlavné pramene pre štúdium a spoznávanie diela sú na štyroch miestach: ',
        '1. Viktória – časť pozostalosti architekta je archivovaná v univerzitnej knižnici v kanadskej Viktórii (Victoria Library). Vnuk architekta Alvin Hudec vlastní taktiež materiály vzťahujúce sa k životu a tvorbe L. E. Hudeca.',
        '2. Šanghaj – od roku 2013 funguje organizácia Shanghai Hudec Cultural Development Center – Hudec memorial room, ktorý vedie pani Liu Suhua v zreštaurovanom rodinnom dome architekta.',
        'Mimo jeho rodinného domu sú zriadené osobitné pamätné izby v niektorých ním navrhnutých budovách. Plány a projekty sa nachádzajú vo viacerých archívoch.',
        '3. Budapešť – občianske združenie Hudec Heritage Project, zmenené v roku 2013 na Hudec Cultural Foundation, spravuje zbierku, ktorá sa zachovala u blízkych príbuzných (Virág Csejdy a ďalší príslušníci jánossiovskej rodinnej vetvy) a zároveň vyvíja odbornú i osvetovú činnosť.',
        '4. Banská Bystrica – samotné historické mesto, ktoré bolo javiskom mladých rokov architekta a jeho rodiny (vrátane rodného domu, ako aj miesta posledného odpočinku), ale taktiež mesto, v ktorom prebiehajú viaceré občianske iniciatívy pre výskum, sprievodcovskú činnosť a sprítomňovanie daného dedičstva.',
        'V roku 2012 vznikla nezisková organizácia Centrum architekta Ladislava Eduarda Hudeca (C.A.L.E.H. n. o.), ktorú založili architekt Igor Teplan a historička architektúry Klára Kubičková. Pri príležitosti 120. výročia narodenia architekta vydali trojjazyčnú publikáciu Po stopách architekta L. E. Hudeca (2013) autorského kolektívu Kláry Kubičkovej, Evy Furdíkovej a Maroša Semančíka. Téma architekta L. E. Hudeca stále rezonuje v publikačnej činnosti, vedeckých konferenciách, médiách a prácach študentov, ako aj v prejavoch úcty zo strany štátnych a mestských inštitúcií. K téme Hudec sa vrátil režisér Ladislav Kaboš a Svatava Maria Kabošová v knihe Ladislav Hudec - otec ázijských mrakodrapov (2014) a Štátna vedecká knižnica v Banskej Bystrici (ŠVK) knihou Ladislav E. Hudec – z Banskej Bystrice do sveta (2018). Sochár Ladislav Sabo sa zúčastnil súťaže na vyhotovenie sochy L. E. Hudeca pre Šanghaj. I keď sa tento zámer neuskutočnil, jeho bronzová busta Ladislava Hudeca bola odhalená v budove Shanghai Urban Planning Exhibition Center a v Spolku architektov Slovenska v Bratislave. Vytvoril  taktiež plaketu pre laureátov architektonickej „Ceny Ladislava Hudeca“, ktorá je udeľovaná slovenskému architektovi úspešne pôsobiacemu v zahraničí. Doterajšími držiteľmi ceny sú architekti Peter Lizoň (2013), Alena Kubová-Gauché (2016) a Peter Černo (2018). Ladislav Sabo viackrát vystavil svoje štúdie k dvojportrétu Juraja a Ladislava Eduarda Hudeca na radnici a v Múzeu SNP v Banskej Bystrici – jeho konečná podoba je súčasťou tejto expozície vo foyeri na prízemí.',
        'V roku 2019 Slovenská pošta (POFIS) uvedie poštovú známku venovanú tvorbe L. E. Hudeca od autora Rudolfa Cigánika. Diplomová práca Kristíny Mičechovej z Filozofickej fakulty Univerzity Komenského v Bratislave o otcovi Jurajovi Hudecovi po jej dopracovaní dostane knižnú podobu. Ich bohatý a plodný život ešte skrýva mnohé nepoznané detaily. Aj obdobie prípravy tejto výstavy prinieslo nečakané poznatky. Nové podnety prináša aj práca šanghajskej akademickej obce a Tongji University v Číne. Profesorka Hua Xiahong spolu s bádateľkou a redaktorkou The Shanghai Daily Michelle Ciao napísali knihu Shanghai Hudec Architecture (2013). V roku 2014 v Šanghaji bol architekt L. E. Hudec uznaný za „Symbol Šanghaja“ na základe verejného hlasovania ako jediný významný cudzinec pôsobiaci v Šanghaji. Budúcnosť je otvorená pre ďalšie skúmanie života a tvorby architekta L. E. Hudeca a jeho otca Juraja Hudeca.',
        '<strong>Pracovný tím projektu:</strong>',
        '<strong>Scenár:</strong>',
        'Klára Kubičková, Eva Furdíková, Rastislav Udžan',
        '<strong>Grafika:</strong>',
        'Peter Javorík, Pavol Štofan, Pavol Snoha',
        '<strong>Jazyková úprava:</strong>',
        'Lenka Mališková',
        '<strong>Jazykové preklady:</strong>',
        'Michal Kiššimon, Marianna Bachledová, Natália Turčinová',
        '<strong>Foto materiály:</strong>',
        'Ján Baláž, Jozef Kreutz, Virag Csejdy, Igor Teplan',
        '<strong>Detský zošit:</strong>',
        'Jana Štofan Styková',
        '<strong>Papierové modely:</strong>',
        'Ladislav Jakubčo, Juraj Gonšor, Miroslav Lisinovič',
        '<strong>Pexeso:</strong>',
        'Lenka Šovčíková',
        'Stálu expozíciu Architekt L. E. Hudec „Banská Bystrica – Šanghaj“ finančne podporili: spoločnosť Contrax plus, spol. s r. o., Fond na podporu umenia a Mesto Banská Bystrica.',
    ]
};

const Chapter15Page = () => {
    return (
        <Blog data={blog} />
    );
};

export default Chapter15Page;