import React from "react";

const Blog = ({ data }) => {
    return (
        <main>
            <article id="singleContent" className="pt-6 pb-6 pt-md-8 pb-md-11 pt-lg-12  pt-xl-20 pb-xl-17">
                <div className="container">
                    <header className="scHeadingHead text-center mb-7 mb-md-10 mb-lg-14">
                        <div className="row">
                            <div className="col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2">
                                <h1 className="mb-5">{data.title}</h1>
                                <div className="d-block scTime text-gray777">{data.subTitle}</div>
                            </div>
                        </div>
                    </header>
                    <div className="row">
                        <div className="col-12">
                            {data.eyeCatch && (
                                <div className="eabDescrText eabDescrTextIII fontSerif">
                                    <p className="mb-7">{data.eyeCatch}</p>
                                </div>
                            )}
                            {data.chapters.map((chapter) => (
                                <p className="mb-6" dangerouslySetInnerHTML={{ __html: chapter }}/>
                            ))}
                            {(data.author || data.production) && (
                                <div className="tagShareFeet border-bottom mt-7 mt-lg-11 pb-2 pb-md-4">
                                    <div className="row">
                                        <div className="col-12 col-sm-9">
                                            <div className="tagsWrap d-flex mb-3 mb-sm-0 pt-sm-1">
                                                <strong className="title flex-shrink-0 font-weight-normal text-secondary">{data.author}</strong>
                                            </div>
                                            <div className="tagsWrap d-flex mb-3 mb-sm-0 pt-sm-1">
                                                <strong className="title flex-shrink-0 font-weight-normal text-secondary">{data.production}</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </article>
        </main>
    );
};

    export default Blog;