import React from "react";

const AboutPage = () => {
    return (
        <main>
            <article className="ourMissionBlock pt-6 pt-md-9 pb-3 pt-lg-12 pb-lg-5 pt-xl-20 pb-xl-10">
                <div className="container">
                    <div className="row">
                        <div className="col-10 offset-1 col-md-8 offset-md-2">
                            <header className="headingHead mb-6 mb-md-10 mb-lg-16 mb-xl-23">
                                <h1 className="mb-4 text-center">Kto sme?</h1>
                                <div className="eabDescrText eabDescrTextIV">
                                    <p>
                                        Centrum architekta Ladislava Eduarda Hudeca (C.A.L.E.H. n. o.) bolo založené v roku 2012 v Banskej Bystrici. Zakladajúcimi
                                        členmi organizácie bol architekt Igor Teplan a historička architektúry Klára Kubičková.
                                    </p>
                                    <p>
                                        Poslaním neziskovej organizácie Centrum architekta L. E. Hudeca je prezentovať životný príbeh architekta Ladislava
                                        Eduarda Hudeca a jeho rodiny. Aj napriek skutočnosti, že podstatná časť architektovho života je spojená s čínskym
                                        Šanghajom, jeho osud je hlboko previazaný a spätý s jeho rodnou Banskou Bystricou.
                                    </p>
                                    <p>
                                        Pri príležitosti 120. výročia narodenia architekta centrum vydalo trojjazyčnú publikáciu Po stopách architekta L. E. Hudeca
                                        (2013) autorského kolektívu Kláry Kubičkovej, Evy Furdíkovej a Maroša Semančíka. V rokoch 2008 a 2018 centrum
                                        organizovalo sprievodné podujatia v rámci „Hudecovho roka“. Centrum v spolupráci so Spolkom architektov Slovenska
                                        (SAS) a mestom Banská Bystrica organizuje ocenenie „Cena Ladislava Hudeca“, ktorá je udeľovaná slovenskému
                                        architektovi úspešne pôsobiacemu v zahraničí. Doterajšími držiteľmi ceny sú architekti Peter Lizoň (2013), Alena KubováGauché (2016) a Peter Černo (2018).
                                    </p>
                                </div>
                            </header>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="ctInfoWrap text-center text-gray777 pt-1">
                                <h3>CALEH n.o.</h3>
                                <strong className="title h4 d-block">Adresa</strong>
                                <p className="mb-5 mb-md-8">
                                    Centrum architekta L. E. Hudeca (C.A.L.E.H. n. o.)<br />
                                    Skuteckého 132/22<br />
                                    974 01 Banská Bystrica<br />
                                    Slovenská Republika<br />
                                </p>
                                <strong className="title h4 d-block">Fakturačné údaje:</strong>
                                <p className="mb-5 mb-md-8">
                                    Názov: Centrum architekta L. E. Hudeca (C.A.L.E.H. n. o.)<br />
                                    Sídlo: Skuteckého 132/22, 974 01 Banská Bystrica<br />
                                    IČO: 45739234<br />
                                    DIČ: 2023617453<br />
                                    IBAN: SK97 0900 0000 0051 8278 9446<br />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </main>
    );
};

export default AboutPage;