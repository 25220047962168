import React from "react";
import logo from '../../images/logos/logo_text_red.png'
import {Link} from "react-router-dom";

const Header = () => {
    return (
        <div className="phStickyWrap phVii w-100">
            <header id="pageHeader" className="position-absolute w-100 bg-white">
                <div className="hdHolder headerFixer">
                    <div className="container">
                        <nav
                            className="navbar navbar-expand-md navbar-light d-block px-0 pt-2 pb-2 pt-md-2 pb-md-2 pt-lg-4 pb-lg-5">
                            <div className="row">
                                <div className="col-5 col-sm-3 col-lg-2">
                                    <div className="logo logoVii mt-lg-1">
                                        <Link to="/">
                                            <img src={logo} className="img-fluid"
                                                 alt="Muzze || Art &amp; History Museum" />
                                        </Link>
                                    </div>
                                </div>
                                <div
                                    className="col-7 col-sm-9 col-lg-10 position-static d-flex justify-content-end pt-lg-1">
                                    <div
                                        className="collapse navbar-collapse pageNavigationCollapse pageNavigationCollapseVii justify-content-md-end"
                                        id="pageNavigationCollapse">
                                        <ul className="navbar-nav mainNavigation mainNavigationVii ">
                                            <li className="nav-item dropdown">
                                                <a className="nav-link" href="javascript:void(0);" role="button"
                                                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Expozícia L. E. Hudec</a>
                                                <div className="dropdown-menu mndDropMenu mndDropMenu p-0">
                                                    <ul className="list-unstyled mnDropList mb-0 pt-1 pb-1 pt-md-4 pb-md-6">
                                                        <li className="hasDropdown">
                                                            <Link to="/architek-hudec">Život L. E. Hudeca</Link>
                                                            <ul className="list-unstyled mnDropList dropdownMenu mb-0 pt-1 pb-1 pt-md-4 pb-md-6">
                                                                <li><Link to="/architek-hudec">Architekt - L. E. Hudec</Link></li>
                                                                <li><Link to="/banska-bystrica">Banská Bystrica - Šanghaj</Link></li>
                                                                <li><Link to="/hudec-detstvo">Detstvo L. E. Hudeca</Link></li>
                                                                <li><Link to="hudec-kaplnka-panny-marie">Kaplnka Panny Márie</Link></li>
                                                                <li><Link to="/hudec-zivotna-cesta">Životné cesty L. E. Hudeca</Link></li>
                                                                <li><Link to="hudec-miesta-zivota">Banská Bystrica - Šanghaj</Link></li>
                                                                <li><Link to="/hudec-shanghai-a-rodina">Šanghaj L. E. Hudeca</Link></li>
                                                                <li><Link to="hudec-shanghai-a-tvorba">Tvorba L. E. Hudeca v Šanghaj</Link></li>
                                                                <li><Link to="/eu-sanghai-usa">Európa - Šanghaj - USA</Link></li>
                                                                <li><Link to="zivot-v-usa">Život v USA</Link></li>
                                                                <li><Link to="/spoznavanie-diel">Diela L. E. Hudeca</Link></li>
                                                            </ul>
                                                        </li>
                                                        <li className="hasDropdown">
                                                            <Link to="/architek-hudec">Rodina L. E. Hudeca</Link>
                                                            <ul className="list-unstyled mnDropList dropdownMenu mb-0 pt-1 pb-1 pt-md-4 pb-md-6">
                                                                <li><Link to="/historia-rodiny">História rodiny</Link></li>
                                                                <li><Link to="/stavitel-juraj-hudec">Staviteľ Juraj Hudec</Link></li>
                                                                <li><Link to="/stavitelske-aktivity-juraja-hudeca">Staviteľstvo Juraja Hudeca</Link></li>
                                                                <li><Link to="/brat-gejza">Brat Gejza Hudec</Link></li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/podujatia" role="button"
                                                   aria-haspopup="true" aria-expanded="false">Podujatia</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/prenajom-priestoru" role="button">Prenájom priestoru</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/o-nas" role="button"
                                                   aria-haspopup="true" aria-expanded="false">O nás</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="d-flex align-items-center ml-3 ml-lg-6">
                                        <a href="javascript:void(0);"
                                           className="hdMenuOpener hdSideMenuOpener position-relative ml-4 d-none d-md-block">
                                            <span className="icnBar position-absolute"><span
                                                className="sr-only">menu</span></span>
                                        </a>
                                        <button className="navbar-toggler pgNavOpener pgNavOpenerVii position-absolute"
                                                type="button" data-toggle="collapse"
                                                data-target="#pageNavigationCollapse"
                                                aria-controls="pageNavigationCollapse" aria-expanded="false"
                                                aria-label="Toggle navigation">
                                            <span className="navbar-toggler-icon"><span className="sr-only">menu</span></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
                <nav
                    className="navbar navbar-light d-flex flex-column navbarSide position-fixed h-100 px-6 pt-10 pb-6 px-md-10 pb-md-10">
                    <ul className="navbar-nav mainNavigation w-100 flex-grow-1 mainSideNavigation  d-block">
                        <li className="nav-item">
                            <Link className="nav-link" to="/" role="button">Domov</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/fotogaleria" role="button">Fotogaléria</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <Link className="nav-link" to="/publikacie" role="button">Publikácie</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <Link className="nav-link" to="/napisali-o-nas" role="button">Napísali o nás</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <Link to="/kontakt" className="nav-link" role="button">Kontakty</Link>
                        </li>
                    </ul>
                    <div className="nvbBottomWrap w-100 flex-shrink-0 text-gray888 pt-10 pb-1">
                        <address className="mb-0">
                            <p>
                                <a href="tel:+421902712756" className="colorInherit textDecorationNone">
                                    +421 902 712 756
                                </a>
                                <br />
                                    <a href="mailto:info@lehudec.org"
                                       className="colorInherit textDecorationNone">info@lehudec.org</a>
                            </p>
                            <ul className="list-unstyled socialNetworks ftSocialNetworks ftSocialNetworksVix d-flex flex-wrap mb-2 mt-11">
                                <li>
                                    <a href="https://www.facebook.com/CALEHbb" target="_blank">
                                        <i className="fab fa-facebook-f" aria-hidden="true"><span
                                            className="sr-only">facebook</span></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/caleh_bb/" target="_blank">
                                        <i className="fab fa-instagram" aria-hidden="true"><span
                                            className="sr-only">instagram</span></i>
                                    </a>
                                </li>
                            </ul>
                        </address>
                    </div>
                    <a href="javascript:void(0);" className="hdMenuOpener hdSideMenuOpener position-absolute">
                        <span className="icnBar position-absolute"><span className="sr-only">menu</span></span>
                    </a>
                </nav>
            </header>
        </div>
    );
};

export default Header;