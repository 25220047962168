import React from "react";
import Blog from "../../components/blog/blog";

const blog = {
    title: 'Staviteľstvo Juraja Hudeca',
    subTitle: '5. Kapitola - Staviteľské aktivity Juraja Hudeca',
    eyeCatch: 'Staviteľské aktivity Juraja Hudeca',
    chapters: [
        '<strong>Stavby u firmy Wünsch:</strong>',
        '<strong>Banskobystrická mestská sporiteľňa (1896)</strong>',
        '(dnes Komunálna poisťovňa, a. s. – Vienna Isurance Group, zvaná dodnes Hungária), Horná 25, Banská Bystrica',
        'Architekt: George Brüggemann (1865 Berlín – 5. 9. 1903 Budapešť; projekt 1896)',
        'Stavba: firma Róberta Wünscha, Banská Bystrica (otvorená 15. 9. 1897)',
        'Juraj Hudec sa zúčastnil na vytýčení perspektívy novej ulice, ktorá dostala svoje miesto po zbúranom Kostole Sv. Ducha, po likvidácii cintorínov a hradieb na tomto úseku. Na rozhraní dvoch ulíc bola postavená reprezentačná budova mestskej sporiteľne. V horných podlažiach bola vlastne slobodárňou úradníkov, ktorí sa sem prisťahovali kvôli úlohám novej administratívy. V prízemí bola reštaurácia a kaviareň. I keď sa na jej plánoch nachádza iba meno Róberta Wünscha, skutočným (v poradí posledným) projektantom bol George Brüggemann. Ich spolupráca pochádzala z rokov stavby podzemnej železnice v Budapešti, kde sa Brüggemann vyznamenal hlavne ako projektant nástupných pavilónov. Honosná palácová stavba pozostáva z troch krídel, v nádvorí je dnes letná reštaurácia a kaviareň. Budova v priebehu 120 rokov plnila rôzne funkcie.',
        '<strong>Budova Uhorského kráľovského súdu (1896 - 1898)</strong>',
        ' (dnes Krajský súd, zvaný Sédria), Skuteckého 7, Banská Bystrica',
        'Architekt: Kiss István (projekt 1896)',
        'Stavba: firma Róberta Wünscha, Banská Bystrica (otvorená 15. 10. 1898)',
        'Komplex budovy súdu a väznice s okolitou parkovou úpravou a oplotením. Hlavná budova spĺňala nielen administratívnu úlohu, ale aj reprezentatívnu. Z chodieb zo strany nádvoria sa vchádzalo do kancelárskych miestností – svojím vestibulom, schodiskovým priestorom a súdnymi sieňami vyvolávala úctu. Exteriér získal v ulici nadradenosť svojou monumentalitou a bohatou štukovou výzdobou. Nad osou hlavného vstupu sa týči nad strechu otvorená vežička – boli tu umiestnené hodiny od miestneho hodinára obohatené o zvuk šíriaci sa doďaleka. Počas svojej 120-ročnej existencie budova slúžila všetkým politickým režimom, ale nezmenila svoj účel, a tak neutrpela prestavbami. Jej stavebná rekonštrukcia a modernizácia prebiehala v rokoch 2006 – 2011, generálnym projektantom bol Ing. arch. Igor Teplan.',
        '<strong>Budova Obchodnej a priemyselnej komory (1901 – 1902)</strong>',
        '(dnes Špecializovaný súd SR), Skuteckého 10, Banská Bystrica',
        'Architekt: Artúr Sebestyén, Budapešť',
        'Stavba: firma Hugyecz – Rosenauer, Banská Bystrica',
        'Budova bola postavená na základe víťazného návrhu celoštátnej súťaže, na ktorej sa zúčastnilo 56 architektov. Víťaz, architekt Sebestyén, na základe pripomienok poroty musel upraviť svoj návrh.',
        'Pôvodne to bola reprezentatívna polyfunkčná (administratívna so zasadacou sálou a obytná) budova. Najkrajším priestorom budovy je zasadacia sieň s bohato vyrezávaným dreveným obkladom (po obnovení v kópiách). Hlavný portál vedie k ústrednému dvojramennému schodisku, osvetlenému oknami so secesnými prvkami a vitrážami.',
        '<strong>Mestská meštianska a obchodná škola (1902)</strong>',
        '(dnes Základná škola Slobodného slovenského vysielača), Skuteckého 8, Banská Bystrica',
        'Architekt: Alfred Jendrassik, Budapešť (projekt 1901 – 1902)',
        'Stavba: firma Hugyecz – Rosenauer, Banská Bystrica',
        'Nárožná budova pre dve školy má jednoduchú dispozíciu – dvomi krídlami vytyčuje smer dvoch ulíc, spájajúce zošikmené nárožie je predpolím pre ústredné schodisko. V interiéri budovy sú triedy  umiestnené na strane ulíc a spájajúce chodby smerom do dvora. Eklekticizmus doby predstavuje zriedkavou ukážkou – čerpá inšpiráciu z neskorej anglickej gotiky a detaily dotvára secesnou ornamentikou. Výrazné priečelie s „režnou“ tmavočervenou tehlou je zjemnené bielymi horizontálnymi pásmi a odľahčené svetlým prízemím. Elegantnosť budovy sa vzťahuje aj na jej dvorné priečelie.',
        '<strong>Stavby firmy Hugyecz – Rosenauer:</strong>',
        '<strong>Budova četníckeho veliteľstva (1903)</strong>',
        '(zvaná Späthova vila, dnes sídlo firmy Contrax plus, spol. s r. o.), Skuteckého 22, Banská Bystrica',
        'Projektant: Juraj Hudec',
        'Stavba: firma Hugyecz – Rosenauer, Banská Bystrica',
        'Budova vznikla na nároží Skuteckého a Kukučínovej ulice na okraji Hudecových a Rosenauerových pozemkov s provizórnymi výrobnými dielňami. I keď Juraj Hudec túto budovu iba prenajal policajnému riaditeľstvu, podľa dnešného pohľadu nemala charakter vily a mala vyhovieť prenajímateľovi. V budove bolo možné umiestniť ako veliteľstvo, tak aj ubytovanie a sklady polície. Po zmene využitia ho však bolo možné premeniť aj na byty, čo sa stalo až na konci dvadsiatych rokov 20. storočia. Novým majiteľom sa stal podnikateľ Späth. To určilo aj názov budovy (Späthova vila). Budova menila viackrát majiteľa aj využitie. V reprezentatívnom vstupe do prízemnej dvorany sa nachádza mimoriadny stavebný skvost, krivka železobetónového schodiska bez podpery s kovaným zábradlím, ukážka toho, čo projektant Hudec ovládal po praxi vo firme Róberta Wünscha. Budova si zachovala aj exteriérové detaily, tvar rozšírenej strechy s vyrezávanými rozetami (v súčasnosti kópie) a s pásom dekoratívnej maľby v podstreší. V roku 1995 bola budova rekonštruovaná a prispôsobená na účely administratívy Všeobecnej zdravotnej poisťovne. Renovačné práce na objekte a prestavba podkrovia na výstavné priestory bola ukončená v roku 2018.',
        '<strong>Vlastný rodinný dom (1906)</strong>',
        '(zvaný Petelenova vila), Skuteckého 15, Banská Bystrica',
        'Projektant: Juraj Hudec',
        ' Stavba: firma Hugyecz – Rosenauer, Banská Bystrica',
        'Roky 1906 – 1914 prežité vo vile znamenali vrcholný rozkvet ako života rodiny, tak aj hospodárskej prosperity jej živiteľa. Syn Ladislav tu žil ako gymnazista, cieľavedome vedený otcom k staviteľskej profesii. Neorenesančný vzhľad vily sa týkal iba jeho exteriéru. Trojpodlažná budova na pôdoryse obdĺžnika zachováva symetriu na svojich fasádach, ale aj v pôdoryse. Do budovy sa vchádza z oboch strán otvorenými schodiskami – kým ľavé schodisko sprístupňovalo hlavne byt a pracovňu, pravé schodisko pokračuje vnútorným schodiskom v budove na tretie podlažie. Akcentom čelnej fasády je stredný rizalit s ústrednou dvojpodlažnou terasou so stĺpmi a balustrádou, ako aj okná s frontónmi. Nad ľavým hlavným vstupom sa nachádza nika so sochou ženskej postavy so zvitkom papiera, symbolizujúca architektúru, s letopočtom 1906. Tvar strechy bol zmenený po požiari v roku 1922. V interiéri vily sa autor riadil predstavami, potrebami a veľkosťou svojej rodiny. Na prízemí umiestnil dva menšie byty, byt na druhom podlaží bol svojou rozlohou a dispozíciou určený pre bývanie rodiny. Zaujímavým prvkom bola tzv. „vnútorná veranda“ na prvom i druhom poschodí od strany dvora. Vonkajšia drevená veranda mala pôvodne taktiež dve podlažia. Podľa dobových fotografií dekoratívne maľované stropy, množstvo predmetov zariadenia, tmavohnedé povrchy drevených okien a dverí neprispievali k dobrému osvetleniu interiérov. Zadný trakt budovy bol orientovaný na severozápad a hlavnú dennú obytnú miestnosť tienila vonkajšia veranda. Avšak nadmerná svetlosť výšky stropov dodávala miestnostiam priestornosť a honosnosť. Pred i za vilou sa nachádzala veľká pestovaná okrasná záhrada, smerom na sever s množstvom ovocných stromov. Pozemky nad ňou patrili až po Jesenského vŕšok Hudecovcom.',
        '<strong>Rodinný dom Ľudovíta Rosenauera (1912)</strong>',
        '(dom pre otca spoločníka Ľudovíta Rosenauera st.), Ulica Viliama Figuša Bystrého 4, Banská Bystrica',
        'Projektant: Juraj Hudec',
        'Stavba: firma Hugyecz – Rosenauer, Banská Bystrica',
        'Pozdĺžna strana prízemného domu vytvára priečelie na uličnej čiare. Obytné miestnosti delí ústredná chodba, v zadnom trakte sú miestnosti pre deti, kuchyňu s príslušenstvom a pre služobníctvo. Projektant vilu obohatil secesnou ozdobnosťou tak na priečelí, ako aj vo vchodovej časti. Bočné strany priečelia vybiehajú do trojuholníkových štítov s reliéfmi robotníkov (staviteľov). Z tohto domu sa zachovala dokumentácia pôdorysu zo dňa 24. júna 1910. Budova plní dodnes úlohu rodinného domu.',
        '<strong>Riaditeľstvo Uhorských kráľovských lesov (1916)</strong>',
        '(dnes LESY Slovenskej republiky), Námestie SNP 8, Banská Bystrica',
        'Architekt: Gyula Wälder, Budapešť',
        'Stavba: firma Hugyecz – Rosenauer, Banská Bystrica',
        'Rozsiahla budova palácového charakteru v eklektickom neobarokovom slohu bola dokončená uprostred 1. svetovej vojny a slúžila hlavne administratívnym účelom. Postavili ju po asanácii komplexu Kammerhofu, sídla riaditeľstva baní a lesov. V priestore jeho rozsiahleho pozemku bolo vytýčené nové prepojenie historického námestia s nábrežím rieky Hron (dnešná Národná ulica). Architektonická kompozícia nového objektu spoluvytvárala túto koncepciu a je pôsobivá zo všetkých zorných uhlov. Bola to posledná budova, ktorú firma Hugyecz – Rosenauer postavila. Budova dnes opäť slúži svojej pôvodnej funkcii, riaditeľstvu lesov.',
        '<strong>Najznámejšie stavby a opravy budov firmy Hugyecz – Rosenauer v širšom regióne:</strong>',
        '<strong>Veža evanjelického kostola a. v. (1907)</strong>',
        'Brezno',
        'Architekt: Gyula Sándy, Ernő Foerka',
        'Budova kostola bola postavená v rokoch 1785 – 1787 na základe tolerančného patentu, ako jeden z prvých evanjelických kostolov na Slovensku. Po dvoch požiaroch dostal svoju súčasnú podobu až začiatkom 20. storočia. Podľa návrhu budapeštianskych architektov Gyulu Sándyho (1868 – 1953) a Ernő Foerka (1868 – 1934) k východnej stene bola pristavaná bohato členená veža s neorománskym tvaroslovím a architektonické tvaroslovie obohatilo aj ďalšie časti bývalej prostej obdĺžnikovej lode.',
        '<strong>Chemický pavilón lesníckej a banskej akadémie (1912)</strong>',
        'Akademická 64, Banská Štiavnica',
        'Banská Štiavnica je nielen mestom s bohatou baníckou minulosťou, ale aj mestom, v ktorom vznikla v roku 1770 Banská akadémia, prvá vysoká škola svojho druhu v Európe. Napriek tomu, že koncom 19. storočia sa rozsah dolovania obmedzil, rozvoj školstva dostal nové podnety zo strany štátu. V roku 1892 vznikla nová Lesnícka akadémia, v roku 1899 nová budova pre Banskú akadémiu. V roku 1912 firma Hugyecz – Rosenauer postavila budovu chemických laboratórií pre obe školy. Patrí k hlavným dominantám severovýchodného územia mesta. Svojím tvaroslovím sa pripája k budove Lesníckej akadémie, líši sa však svojím pôdorysom, ktorý má na zadnej strane budovy tri kolmé krídla. Plány školy sa nezachovali. V jeho interiéroch boli vytvorené viaceré chemické laboratóriá so zariadením zodpovedajúcim úrovni svojej doby. Ich vybavenie navrhli samotní profesori po výskumoch v zahraničí, Sobó Jenő (Staudner), Gregor Bencze, Róbert Schelle. Po roku 1918 sa škola presťahovala do Šopronu v Maďarsku.',
        '<strong>Kráľovské katolícke gymnázium (1914)</strong>',
        'Akademická 52, Banská Štiavnica',
        'Táto budova sa nachádza v priestore nových budov Banskej a lesníckej akadémie. Svojimi dvomi krídlami vytvárala novú uličnú čiaru a zákrutu na klesajúcej ceste smerom do starého mesta. Má jednoduchú dispozíciu – školské chodby a z nich prístupné školské triedy. Jej projektantom bol Lajos Ybl, budapeštiansky architekt. Do jeho ateliéru mal nastúpiť po skončení vysokej školy Ladislav Hudec. Pri schvaľovaní projektu si pravdepodobne nikto neuvedomil úskalia, ktoré tu čakali na staviteľov, členitosť a spád reliéfu podložia, neodkryté neďaleké ruiny benediktínskeho kláštora. Samotná budova bola preťažená svojím kamenným obkladom. Po kolaudácii sa postupne dala do pohybu. Za kvalitu stavby podľa zákona zodpovedal staviteľ. Keď bola budova odovzdaná do užívania československému školstvu, k zodpovednosti bol volaný samotný Juraj Hudec, lebo jeho spoločník i architekt žili v Budapešti. Československý štát žaloval Juraja Hudeca. Niekoľko dní po obdržaní exekučného príkazu Juraj Hudec zomiera. Škola prešla opravami a stojí dodnes. Ale ešte stále nemá šťastie – čaká na nové využitie.',
        '<strong>Kaplnka Panny Márie Kráľovnej (1914)</strong>',
        'Vyhne',
        'Kaplnke Panny Márie Kráľovnej je venovaná samostatná časť expozície.',
        '<strong>Mestská reduta</strong>',
        'Brezno',
        'Brezno získalo mestské výsady v roku 1380, opevneným a plnoprávnym mestom sa stalo v polovici  17. storočia. Jeho architektúra trpela viacerými požiarmi. Začiatkom 20. storočia rozvíjajúci sa priemysel v jeho okolí, ako aj objavovanie prírodných krás podporili úmysel mesta postaviť na hlavnom námestí výpravnú mestskú redutu. V roku 1908 vypísali verejnú súťaž, na ktorej sa zúčastnilo osemnásť architektov. Víťazom sa stala dvojica Fabian Wannenmacher (1882 – 1967) a Miklós Führer (1873 – 1948). Mestská reduta bola postavená v rokoch 1909 – 1910. Mala všetky očakávané funkcie – slúžila ako hotel (dvanásť izieb), reštaurácia s potrebným zázemím, mala veľkú sálu pre divadelné predstavenia a spoločenské udalosti. Veľkorysosť a veľkomestské secesné tvaroslovie jej exteriéru a interiérov zmenilo charakter historického námestia. Naplnenie jeho funkcií v súčasnosti však nezodpovedá pôvodným predstavám.',
    ]
};

const Chapter5Page = () => {
    return (
        <Blog data={blog} />
    );
};

export default Chapter5Page;