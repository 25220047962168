import React from "react";
import Blog from "../../components/blog/blog";

const blog = {
    title: 'Brat Gejza Hudec',
    subTitle: '12. kapitola - Gejza Hudec, brat architekta (22. 2. 1907 – 23. 2. 1933)',
    eyeCatch: 'Gejza Hudec, brat architekta (22. 2. 1907 – 23. 2. 1933)“',
    chapters: [
        'Posledné dieťa v rodine Juraja Hudeca, Gejza Hudec (Hugyecz Géza), narodené v období najväčšieho rozmachu rodiny i stavebnej firmy v novej rodinnej vile 22. februára 1907. V rokoch 1913 – 1921 absolvoval štyri ročníky ľudovej chlapčenskej evanjelickej školy a štyri ročníky evanjelického gymnázia v Banskej Bystrici, v roku 1922 piaty ročník evanjelického gymnázia v Bratislave. Bolo to obdobie úplného rozvratu jeho rodiny – smrť otca, úmrtie matky, strata všetkého rodinného majetku v Banskej Bystrici.',
        'Jedinou istotou ostala súdržnosť súrodencov. Od roku 1922 sa o neho starali manželia Jánossiovci – sestra Jolana, ktorá sa vydala za profesora Istvána Jánossiho. Keďže živiteľ rodiny neovládal slovenčinu, vo svojej pedagogickej práci pokračoval v Budapešti a tu pokračoval vo svojich štúdiách aj Gejza.',
        'Rodinná tradícia ako aj jeho nadanie ho predurčili po maturite ku štúdiu architektúry (1925 – 1929, Budapešť). Najstarší brat Ladislav plánoval príchod svojho brata do Šanghaja, dokonca mal v úmysle zanechať mu svoju firmu. Považoval ho za veľmi nadaného. Gejza bez problémov pokračoval vo svojom štúdiu, avšak jeho bohatý citový život ho stále priťahoval „domov“ k zeleným stráňam a adrenalínovým lyžovačkám a horolezectvu v rodnom kraji. Vracal sa do Pešti s mnohými medailami za víťazstvá v lyžiarskych pretekoch a so spomienkami na starých priateľov.  Zatiaľ sa nevie presne, čo zapríčinilo, že vysokú školu opustil bez diplomu. V liste z roku 1929 spomína návrat z kasární v Revúcej, je možné, že jeho štúdium prerušilo povolanie na povinnú vojenskú službu v Československej armáde.',
        'Vzápätí dostáva príkaz od Ladislava na cestu do Šanghaja cez USA, ale peniaze mu posiela iba na cestu do USA. Ďalšiu cestu až do Šanghaja si mal zabezpečiť z vlastného zárobku a pritom sa naučiť perfektne po anglicky. Svoju situáciu opisuje v liste banskobystrickému priateľovi z New Yorku. Do Šanghaja sa dostal, ale vzťah bratov sa nezlepšil. Gejza sa osamostatnil a žil vlastným životom, žiaľ, iba krátko. Na zauzlenie čriev ho operovali už počas americkej cesty, v Šanghaji sa to zopakovalo s tragickým koncom. Zomrel 23. februára 1933. V roku 1934 bol pochovaný do rodinnej krypty v Banskej Bystrici.',
        '<strong>Úryvok listu priateľovi Kornelovi Reinhardovi písaný z New Yorku</strong> (uvádzame v pôvodnom doslovnom znení):',
        '<strong>Adresát:</strong>',
        'Reinhardt Kornél, bankový úradník<br />Horná ul. 8<br />Banská Bystrica<br />Czecho-Slovakia<br />Praha 16. 2. 1930, Banská Bystrica 18. 2. 1930',
        '<strong>Odosielateľ:</strong>',
        'G. G. Hugyecz<br />1531 Benson St.,<br />Bronx<br />New York<br />3. februára 1930',
        '<i>Milý Koro!<br />' +
        'Nehnevaj sa, že si musel tak dlho čakať, ale teraz mi naozaj nezostáva čas<br />' +
        'na písanie listov. Aj teraz píšem iba narýchlo, čo ma napadne,<br />' +
        'nesystematicky. Odpustíš mi? 4 týždne som chodil za zamestnaním vo<br />' +
        'svojom odbore, ale nedostal som ho ani bez nároku na výplatu. Pomery sú<br />' +
        'veľmi zlé po jesennom krachu burzy + v Únii sú milióny nezamestnaných,<br />' +
        'z toho väčšina v New Yorku. Všade znižujú počty zamestnancov. Mesiac<br />' +
        'som chodil po architektoch po stavbách a hľadal som prácu ako kreslič,<br />' +
        'stavbár, nájomný robotník dokonca aj zadarmo, aby som bol na stavbe do<br />' +
        'septembra, kedy sa chcem prihlásiť na večernú univerzitu.<br />' +
        '<br />' +
        'Vylúčené. Bez výplaty sa nesmie pracovať, to nedovoľujú odbory,<br />' +
        'aby som nezabral pracovné miesto niekoho, kto potrebuje výplatu.<br />' +
        'Ale do frasa, ak budem sedieť doma, tak sa nikdy nenaučím po<br />' +
        'anglicky. Potrebujem zamestnanie, nech sa deje, čo sa deje. Znovu som<br />' +
        'prešiel mesto, teraz už s inými plánmi – nájsť hocijakú prácu. Tak som si<br />' +
        'našiel terajšie miesto – som čašníkom za denných 1,25 dolára a jedlo.<br />' +
        'Nedeľa je voľná. Ak to spočítaš, tak zistíš, že to akurát stačí na nájom (25<br />' +
        'dolárov) a na električku – ráno a večer 1 - 1 hodina cesty, keď mám školu<br />' +
        'večer ešte 1 a pol hodiny). Nevadí, bude aj lepšie. Večer chodím do školy.<br />' +
        'Ináč sa cítim výborne. Úplne voľne, na vlastných nohách a vyžijem.<br />' +
        'Pravda, momentálne by som ani nemal čas na iné výdavky, tak mám nabitý<br />' +
        'deň. Je to výborný pocit, že môžem robiť, čo chcem. A plánujem všeličo, čo<br />' +
        'sa týka mojej budúcnosti.<br />' +
        '<br />' +
        'Uvidím, čo sa mi podarí z toho uskutočniť. To už vidím, že to potrvá<br />' +
        'dlho, kým sa usadím. 4 - 5 rokov Amerika, potom niekoľko rokov Čína.<br />' +
        'Kto vie, kam pôjdem odtiaľ? Čo bude s vnukmi? Takto by som sa v najlepšom<br />' +
        'prípade mohol oženiť až okolo 35 - ky, ak ma počas tak dlhotrvajúcej samoty<br />' +
        'nepochytí chuť ostať starým mládencom. (Ale tomu predsa len neverím. Ak aj neskôr, ale predsa!) Zatiaľ ma zamestnávajú takéto pevné plány a v skutočnosti nemám obavy z<br />' +
        ' takéhoto nebezpečenstva. Veď 10 rokov kľudne môžem počkať. Stačí, keď<br />' +
        'budem až potom rozmýšľať.<br />' +
        'Hoci aké milé dievča je práve teraz na obzore. Škoda, že sa neviem zblázniť teraz<br />' +
        'do niekoho. Mr. Burch, priateľ Laciho, ma pozval nedávno k nim na večeru. Býva 32 kilometrov od<br />' +
        'New Yorku, v menšom vilovom mestečku. Má dvoch synov a 19 - ročnú dcéru, študujúcu dejiny.<br />' +
        'Dievčina, ale aká. Naše dievčatá by sa mohli od nej učiť prirodzenosti.<br />' +
        'Zatiaľ poznám zblízka iba ju, ale ak by sa jej podobali aj ostatné, tak je to šťastný štát.<br />' +
        'Afektovane sa hrať na veľkú dámu? Ani v najmenšom. Prirodzená, bezprostredná, milá –<br />' +
        'akokeby mi bola mladšou sestrou <strong>(zle čitateľné)</strong>.<br />' +
        'Čo však môžem robiť – večerami, keď spomínam na naše kamarátky, aj ju priradím medzi ne.<br />' +
        '<br />' +
        'Starký, mohol by si prispieť zopár podarenými obrázkami – lyžovanie, túry, dievčatá.<br />' +
        'Jednej nedele, keď ma chytila túžba po lyžovačke, som vyrobil z pozostatkov mojej zelenej skokanskej košele a z kartonu tvar štítu. Doprostriedka som prilepil tvoj záber<br />' +
        'zo zasneženej Patrie v Štrbe, okolo moju zbierku klubových znakov a kvet plesnivca, ktorý si mi daroval. Povesil som ho na stenu mojej izby k posteli. Nad mojou hlavou visí a <br />' +
        'stráži ma plstená bábika, (halena baba), pamiatka z Kremnice.<br />' +
        'Pozdravujem všetkých. Bozkávam ťa Géza<br />' +
        '<br />' +
        '<strong>(písané kolmo na stranu drobným písmom)</strong><br />' +
        'Z bystrických pamiatok sú stále so mnou retiazky od Zoliho a od teba.<br />' +
        'Zoliho retiazku nosím na krku a na tvojej nosím hodinky. Takto denne<br />' +
        'na vás myslím.',
    ]
};

const Chapter12Page = () => {
    return (
        <Blog data={blog} />
    );
};

export default Chapter12Page;