import React from 'react'
import InstagramFeed  from 'react-ig-feed'
import 'react-ig-feed/dist/index.css'

const IgFeed = () => {
    return (
        <section className="instaFeedBlock text-center bg-grayf8f8f8 hasBdr pt-6 pt-lg-8 pt-xl-12">
            <div className="container">
                <h2 className="mb-13">Pozrite si nás aj na Instagrame</h2>
            </div>
            <InstagramFeed
                token="IGQVJYQVRrUkJVWEhnOUtIVG9HY0VfckZAPVU5WWEFfWGJnb1hIeTJhRUZA5WW1zcHotS19DLXAzV2E2VFhTcXNITG1nODZAuZAEJWeHNLVDhtaHJwWjFBSUFBQ2J0R3JFZAXB5YV9nVjA5VkdoR2JPaTR0TgZDZD"
                counter="6"
            />
        </section>
    );
};

export default IgFeed;