import React from "react";
import titleImg from '../images/space-wide.png';
import img01 from '../images/gallery/01.jpg';
import img02 from '../images/gallery/02.jpg';
import img03 from '../images/gallery/03.jpg';
import img04 from '../images/gallery/04.jpg';
import img05 from '../images/gallery/05.jpg';
import img06 from '../images/gallery/06.jpg';
import img07 from '../images/gallery/07.jpg';
import img08 from '../images/gallery/08.jpg';
import img09 from '../images/gallery/09.jpg';
import img10 from '../images/gallery/10.jpg';
import img11 from '../images/gallery/11.jpg';
import img12 from '../images/gallery/12.jpg';
import img13 from '../images/gallery/13.jpg';
import img14 from '../images/gallery/14.jpg';
import img15 from '../images/gallery/15.jpg';
import img16 from '../images/gallery/16.jpg';
import img17 from '../images/gallery/17.jpg';
import img18 from '../images/gallery/18.jpg';
import img19 from '../images/gallery/19.jpg';

const photos = [
    img01,
    img02,
    img03,
    img04,
    img05,
    img06,
    img07,
    img08,
    img09,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19
]

const PhotoGalleryPage = () => {
    return (
        <main>
            <header className="mainHeadingHead position-relative bgCover w-100 d-flex text-white"
                    style={{ backgroundImage: `url(${titleImg})` }}>
                <div className="mhhAlignHolder d-flex w-100 align-items-center position-relative">
                    <div className="container py-6">
                        <h1 className="text-white mb-0">Fotogaléria</h1>
                    </div>
                </div>
            </header>
            <section className="imageGalleryBlock pt-8 pb-3 pb-md-6 pt-lg-13 pb-lg-10 pt-xl-19 pb-xl-16">
                <div className="container-fluid">
                    <div className="px-xlwd-17">
                        <div className="row isoContentHolder">
                            {photos.map((photo) => (
                                <div className="col-12 col-sm-4 col-xl-3 objects isoCol">
                                    <figure className="galFig galFigII position-relative mx-auto mx-md-0 mb-6">
                                        <a data-fancybox="gallery" href={photo}>
                                            <img src={photo} className="img-fluid"
                                                 alt="image description" />
                                        </a>
                                    </figure>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default PhotoGalleryPage;