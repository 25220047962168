import React from "react";
import title from '../images/publications/title.jpg';
import book1 from '../images/publications/01.jpg';
import book2 from '../images/publications/02.jpg';

const PublicationsPage = () => {
    return (
        <main>
            <header
                className="mainHeadingHead position-relative bgCover w-100 d-flex text-white"
                style={{ backgroundImage: `url(${title})` }}
            >
                <div className="mhhAlignHolder d-flex w-100 align-items-center position-relative">
                    <div className="container py-6">
                        <h1 className="text-white mb-0">Publikácie</h1>
                    </div>
                </div>
            </header>
            <article className="ourMissionBlock pt-6 pt-md-9 pb-3 pt-lg-12 pb-lg-5 pt-xl-20 pb-xl-10">
                <div className="container">
                    <div className="row">
                        <div className="col-10 offset-1 col-md-8 offset-md-2">
                            <header className="headingHead mb-6 mb-md-10 mb-lg-16 mb-xl-23">
                                <h2 className="mb-4 text-center">Po stopách architekta L. E. Hudeca (2013)</h2>
                                <div className="eabDescrText eabDescrTextIV">
                                    <p>
                                        Pri príležitosti 120. výročia narodenia architekta centrum vydalo trojjazyčnú publikáciu Po stopách architekta
                                        L. E. Hudeca (2013) autorského kolektívu Kláry Kubičkovej, Evy Furdíkovej a Maroša Semančíka.
                                    </p>
                                </div>
                            </header>
                        </div>
                        <div className="col-10 offset-1 col-md-8 offset-md-2">
                            <header className="headingHead mb-6 mb-md-10 mb-lg-16 mb-xl-23">
                                <h2 className="mb-4 text-center">Staviteľ Juraj Hudec 1858 - 1920 (2020)</h2>
                                <div className="eabDescrText eabDescrTextIV">
                                    <p>
                                        Pri príležitosti 100. výročia úmrtia Juraja Hudeca, osobnosti Banskej Bystrice a otca slávneho architekta
                                        Ladislava E. Hudeca pôsobiaceho v Šanghaji vydal C.A.L.E.H. n.o. publikáciu Staviteľ Juraj Hudec 1858 –
                                        1920. Ďakujeme Fondu na podporu umenia a Banskobystrickému samosprávnemu kraju za ich podporu pri
                                        projekte tejto knihy. Za spoluprácu ďakujeme autorke Kristíne Mišechovej. Za fotografie stavieb Olja Triaška
                                        Stefanović, grafikovi Samo Čarnoký za celé grafické spracovanie knihy a za ikonky stavieb do mapky
                                        Petrovi/Palovi Snohovi.
                                    </p>
                                </div>
                            </header>
                        </div>
                        <div className="col-10 offset-1 col-md-8 offset-md-2">
                            <h3 className="mb-4 text-center">Obe knihy si môžete u nás zakúpiť. Neváhajte nás kontaktovať.</h3>
                        </div>
                    </div>
                    <div className="row mx-xl-n4">
                        <div className="col-12 col-md-6 px-xl-4">
                            <figure className="onImageColumn onImageColumnLarge mb-6 mb-xl-8">
                                <img src={book1} className="img-fluid w-100 h-100 imgFit"
                                     alt="image description" />
                            </figure>
                        </div>
                        <div className="col-12 col-md-6 px-xl-4">
                            <figure className="onImageColumn onImageColumnLarge mb-6 mb-xl-8">
                                <img src={book2} className="img-fluid w-100 h-100 imgFit"
                                     alt="image description" />
                            </figure>
                        </div>
                    </div>
                </div>
            </article>
        </main>
    );
};

export default PublicationsPage;