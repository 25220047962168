import React from "react";
import Template from "./components/template";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import HomePage from "./pages/homepage";
import Page404 from "./pages/404";
import AboutPage from "./pages/about";
import EventsPage from "./pages/events";
import ContactPage from "./pages/contact";
import MediaPage from "./pages/media";
import ScrollToTop from "./components/scrollToTop";
import SpaceRental from "./pages/spaceRental";
import PhotoGalleryPage from "./pages/photoGallery";
import PublicationsPage from "./pages/publications";
import HudecArchitectPage from "./pages/hudec-life/architect";
import HudecShanghaiPage from "./pages/hudec-life/shanghai";
import HudecChildhoodPage from "./pages/hudec-life/childhood";
import HudecMariaPage from "./pages/hudec-life/maria";
import HudecLifeRoadPage from "./pages/hudec-life/lifeRoad";
import HudecShanghai2Page from "./pages/hudec-life/shanghai2";
import HudecShanghai3Page from "./pages/hudec-life/shanghai3";
import HudecShanghai4Page from "./pages/hudec-life/shanghai4";
import Chapter13Page from "./pages/hudec-life/chapter13";
import Chapter14Page from "./pages/hudec-life/chapter14";
import Chapter15Page from "./pages/hudec-life/chapter15";
import Chapter3Page from "./pages/hudec-family/chapter3";
import Chapter4Page from "./pages/hudec-family/chapter4";
import Chapter5Page from "./pages/hudec-family/chapter5";
import Chapter12Page from "./pages/hudec-family/chapter12";

const App = () => {
    return (
        <Router>
            <ScrollToTop />
            <Template>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/o-nas" element={<AboutPage />} />
                    <Route path="/podujatia" element={<EventsPage />} />
                    <Route path="/kontakt" element={<ContactPage />} />
                    <Route path="/napisali-o-nas" element={<MediaPage />} />
                    <Route path="/prenajom-priestoru" element={<SpaceRental />} />
                    <Route path="/fotogaleria" element={<PhotoGalleryPage />} />
                    <Route path="/publikacie" element={<PublicationsPage />} />
                    <Route path="/architek-hudec" element={<HudecArchitectPage />} />
                    <Route path="/banska-bystrica" element={<HudecShanghaiPage />} />
                    <Route path="/hudec-detstvo" element={<HudecChildhoodPage />} />
                    <Route path="/hudec-kaplnka-panny-marie" element={<HudecMariaPage />} />
                    <Route path="/hudec-zivotna-cesta" element={<HudecLifeRoadPage />} />
                    <Route path="/hudec-miesta-zivota" element={<HudecShanghai2Page />} />
                    <Route path="/hudec-shanghai-a-rodina" element={<HudecShanghai3Page />} />
                    <Route path="/hudec-shanghai-a-tvorba" element={<HudecShanghai4Page />} />
                    <Route path="/eu-sanghai-usa" element={<Chapter13Page />} />
                    <Route path="/zivot-v-usa" element={<Chapter14Page />} />
                    <Route path="/spoznavanie-diel" element={<Chapter15Page />} />
                    <Route path="/historia-rodiny" element={<Chapter3Page />} />
                    <Route path="/stavitel-juraj-hudec" element={<Chapter4Page />} />
                    <Route path="/stavitelske-aktivity-juraja-hudeca" element={<Chapter5Page />} />
                    <Route path="/brat-gejza" element={<Chapter12Page />} />
                    <Route path="*" element={<Page404 />} />
                </Routes>
            </Template>

        </Router>

    );
};

export default App;