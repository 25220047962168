import React from "react";
import img1 from '../../images/gallery/01.jpg';
import img2 from '../../images/gallery/02.jpg';
import img3 from '../../images/gallery/03.jpg';
import img4 from '../../images/gallery/05.jpg';
import img5 from '../../images/gallery/06.jpg';
import img6 from '../../images/gallery/07.jpg';

const ExposurePreview = () => {
    return (
        <section className="exploreBlock bg-secondary pt-6 pb-3 pt-md-12 pb-md-6 pt-lg-17 pb-lg-12 pt-xl-22 pb-xl-17">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-4 pr-lg-10 pr-xl-16 stickyContainer">
                        <header className="headHeading hhFzMedium text-gray888 pt-2 mb-6 stickyElem sceHeadStick">
                            <h2 className="text-white mb-5">"Banská Bystrica - Šanghaj"</h2>
                            <p className="mb-3 mb-lg-6 mb-xl-10">Stála expozícia venovaná životu a dielu
                                architekta Ladislava Eduarda Hudeca a
                                jeho otcovi, staviteľovi Jurajovi Hudecovi.</p>
                            <a href="/fotogaleria" className="d-none d-md-inline-block  align-top teeSeeAllBtn inDark mt-2">Zobraziť fotogalériu <i className="icomoon-arrowCircleRight align-middle icn ml-1"><span className="sr-only">icon</span></i></a>
                        </header>
                    </div>
                    <div className="col-12 col-md-8">
                        <div className="row makeItMasonery">
                            <div className="col-12 col-sm-6 mimItem">
                                <article className="collectionColumn mb-6 text-center position-relative">
                                    <div className="imgHolder">
                                        <img src={img1} className="img-fluid w-100 d-block" alt="image description" />
                                    </div>
                                </article>
                            </div>
                            <div className="col-12 col-sm-6 mimItem">
                                <article className="collectionColumn mb-6 text-center position-relative">
                                    <div className="imgHolder">
                                        <img src={img2} className="img-fluid w-100 d-block" alt="image description" />
                                    </div>
                                </article>
                            </div>
                            <div className="col-12 col-sm-6 mimItem">
                                <article className="collectionColumn mb-6 text-center position-relative">
                                    <div className="imgHolder">
                                        <img src={img3} className="img-fluid w-100 d-block" alt="image description" />
                                    </div>
                                </article>
                            </div>
                            <div className="col-12 col-sm-6 mimItem">
                                <article className="collectionColumn mb-6 text-center position-relative">
                                    <div className="imgHolder">
                                        <img src={img4} className="img-fluid w-100 d-block" alt="image description" />
                                    </div>
                                </article>
                            </div>
                            <div className="col-12 col-sm-6 mimItem">
                                <article className="collectionColumn mb-6 text-center position-relative">
                                    <div className="imgHolder">
                                        <img src={img5} className="img-fluid w-100 d-block" alt="image description" />
                                    </div>
                                </article>
                            </div>
                            <div className="col-12 col-sm-6 mimItem">
                                <article className="collectionColumn mb-6 text-center position-relative">
                                    <div className="imgHolder">
                                        <img src={img6} className="img-fluid w-100 d-block" alt="image description" />
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
    
    export default ExposurePreview;