import React from "react";
import Blog from "../../components/blog/blog";

const blog = {
    title: 'Život v USA',
    subTitle: '14. kapitola - Život v USA/ Berkeley a projekt Challet (1948 – 1958)',
    eyeCatch: 'Život v USA/ Berkeley a projekt Challet (1948 – 1958)',
    chapters: [
        'Ladislav Hudec si vyberie za svoj nový domov mesto Berkeley v Kalifornii v blízkosti San Francisca. Rozhodne sa nepokračovať v architektonickej práci, venuje sa svojim záľubám a cestovaniu. Píše, príležitostne prednáša, fotografuje, filmuje, usporadúva dokumentáciu svojej celoživotnej činnosti. Má kontakt so všetkými deťmi i súrodencami, starostlivo sleduje ich osudy.',
        'Do Číny ani do Československa a Maďarska za daného politického stavu nemôže ísť ani na návštevu. Odhodlá sa vytvoriť si ilúziu svojich nerealizovateľných túžob: keď sa už nemôže vrátiť domov do ihličnatých vrchov a lesov Harmanca a Španej Doliny, chce si postaviť nový rodinný dom v blízkom Údolí indiánskych žien (Squaw Valley – v roku 1960 sa tu konala zimná olympiáda). Aj tam sú ihličnaté stromy, i keď nevoňajú tak silne ako doma alebo vo Švajčiarsku. Svoj projekt nakreslil v lete roku 1957 v Mníchove, odkiaľ odišli s manželkou do Švédska hľadať firmu na výrobu domu.',
        'Nakoniec si vybrali švajčiarsku firmu Chalettfabrik MURER Beckenried. Stavba projektu Challet bola celá drevená, na ktorej Hudec konečne mohol použiť svoje znalosti tesárskeho remesla a vytvoriť budovu bez kovových článkov, iba s tesárskymi spojmi. Na poschodí boli situované spálne s kúpeľňami, na prízemí veľká obytná jedáleň. Projekt venoval svojej žene Gizele. Do Squaw Valley firma doručila v roku 1958 prefabrikované časti dreveného domu. Pri dokončovaní montáže Ladislav Hudec zomrel na infarkt 26. októbra 1958 vo veku 65 rokov.'
    ]
};

const Chapter14Page = () => {
    return (
        <Blog data={blog} />
    );
};

export default Chapter14Page;