import React from "react";
import building from '../images/caleh.jpg'

const ContactPage = () => {
    return (
        <main>
            <article className="ourMissionBlock pt-6 pt-md-9 pb-3 pb-md-9 pt-lg-12 pb-lg-13 pt-xl-20 pb-xl-22">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-8 offset-md-2">
                            <div className="px-xl-5">
                                <header className="headingHead text-center">
                                    <h1 className="mb-3">Kontakt</h1>
                                    <div className="eabDescrText eabDescrTextII text-gray888 fontSerif">
                                        <p>Navštívte našu expozíciu a pozrite si úžasné diela, ktoré počas svojho života L. E. Hudec vytvoril</p>
                                    </div>
                                </header>
                            </div>
                        </div>
                    </div>
                    <hr className="mt-5320 mb-12 mt-lg-8" />
                    <div className="row">
                        <div className="col-12 col-md-8 order-md-2">
                            <img src={building} alt="Budova expozície L. E. Hudeca" />
                        </div>
                        <div className="col-12 col-md-4 order-md-1">
                            <div className="ctInfoWrap text-gray777 pt-1">
                                <strong className="title h4 d-block">Otváracie hodiny</strong>
                                <p>
                                    Pre návštevu či komentovanú prehliadku stačí zavolať na číslo
                                </p>
                                <p>
                                    +421 902 712 756<br />
                                    +421 905 800 775
                                </p>
                                    <strong className="title h4 d-block mb-2">Adresa</strong>
                                <address className="mb-5 mb-md-9">
                                    <p className="mb-5">Skuteckého 22 Banská Bystrica<br />(Späthova vila)</p>
                                </address>
                                <strong className="title h4 d-block mb-2">Tel. čísla / Email</strong>
                                <p>
                                    <a href="tel:+421902712756" className="textDecorationNone">+421 902 712 756</a><br />
                                    <a href="tel:+421905800775" className="textDecorationNone">+421 905 800 775</a><br />
                                    <a href="mailto:info@lehudec.org" className="textDecorationNone">info@lehudec.org</a>
                                </p>
                                <strong className="title h4 d-block mb-4">Sociálne média</strong>
                                <ul className="list-unstyled socialNetworks ctSocialNetworks d-flex flex-wrap mb-0">
                                    <li>
                                        <a href="https://www.facebook.com/CALEHbb" target="_blank">
                                            <i className="fab fa-facebook-f"><span
                                                className="sr-only">facebook</span></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/caleh_bb/" target="_blank">
                                            <i className="fab fa-instagram"><span
                                                className="sr-only">instagram</span></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </main>
    );
};

export default ContactPage;