import React from "react";
import Blog from "../../components/blog/blog";

const blog = {
    title: 'Kaplnka Panny Márie',
    subTitle: '7. kapitola - Kaplnka Panny Márie Kráľovnej (1913 – 1914)',
    eyeCatch: 'Kaplnka Panny Márie Kráľovnej (1913 – 1914)',
    chapters: [
        'V severozápadnej časti Štiavnických vrchov, v malebnom prostredí obce Vyhne je postavená kaplnka Panny Márie Kráľovnej, prvá realizovaná stavba architekta L. E. Hudeca, okrem projektu rodinnej hrobky na evanjelickom cintoríne v Banskej Bystrici.',
        'Od roku 1564 kúpele Vyhne vlastnilo mesto Banská Štiavnica. V roku 1734 nechal magistrát mesta vo Vyhniach postaviť kaplnku zasvätenú sv. Jánovi Nepomuckému, ktorá však pre rozširovanie kúpeľov bola v roku 1896 asanovaná. Začiatkom 20. storočia bol založený stavebný fond pre získanie finančných prostriedkov na novú kaplnku, ktorá mala „vyrásť“ na okraji smrekového lesa v hornom parku kúpeľov.',
        'Na jeseň roku 1913 sa začalo s výstavbou kaplnky. Na jej realizácii sa podieľala firma architektovho otca Juraja Hudeca (Hugyecz – Rosenauer). Stavba stála 8000 korún, z čoho 2000 korún dofinancovala stavebná spoločnosť vo forme pôžičky. Mesto Banská Štiavnica prispelo majoritnou sumou 6000 korún. Dodržaný rozpočet zaručil výstavbu kaplnky so všetkými náležitosťami a prevedenými detailmi.',
        'Kaplnka bola vysvätená v nedeľu 26. júla 1914 vyhnianskym farárom Jozefom Daubnerom. Po vysviacke nasledovala prvá omša s účasťou obyvateľov obce a kúpeľných hostí. O dva dni neskôr Rakúsko-Uhorsko vypovedalo vojnu Srbsku a začala sa 1. svetová vojna.',
        'Stavba svojou prostou formou architektonických hmôt stelesňuje symboliku a nadčasovosť. Použitá čistota a materiálová pravdivosť podporuje prírodný kolorit lesného prostredia. Na masívnu kamennú konštrukciu stien z miestneho kameňa ryolitu (hákliková väzba), odľahčenú symetrickým rozmiestnením okenných otvorov, dosadá ľahká trámová konštrukcia a azbestovocementová strešná krytina s dreveným štítom, ktorý zdobia v troch radoch vyrezávané kríže. Zvonica na streche umocňuje vstupné priečelie kaplnky obohatenej o otvor nad dverami (volské oko). V symbolickej rovine evokuje vstupná brána vchod do podzemných banských šácht. Presbytérium kaplnky je zaklenuté valenou klenbou. V interiéri je dominantný kazetový strop s maliarskou výzdobou z roku 1914, ktorá je dielom Jozefa Vavrína. Ornamentálnosť so secesnou štylizáciou rastlinných motívov a tesárskou výzdobou zásekov a vrubov s farebnou akcentáciou bielej a zelenej farby je odkazom na ľudový vzor. V interiéri je pôvodná drevená plastika sv. Jána Nepomuckého, ktorá bola zachránená z pôvodnej kaplnky vo Vyhniach. Celkový regionálny charakter kaplnky je inšpirovaný architektúrou karpatskej oblasti. Od júla 2014 je kúpeľná Kaplnka Panny Márie Kráľovnej národnou kultúrnou pamiatkou.'
    ]
};

const HudecMariaPage = () => {
    return (
        <Blog data={blog} />
    );
};

export default HudecMariaPage;