import React from "react";
import Blog from "../../components/blog/blog";

const blog = {
    title: 'Banská Bystrica - Šanghaj',
    subTitle: '6. kapitola - Detstvo a študentské roky L. E. Hudeca',
    eyeCatch: 'Detstvo a študentské roky L. E. Hudeca',
    chapters: [
        'L. E. Hudec sa narodil ako prvorodené dieťa staviteľa Juraja Hudeca a jeho manželky Pavly, rodenej Škultétyovej, dňa 8. januára 1893 v Banskej Bystrici. Detstvo prežil v rodine so sestrami Editou, Líviou, Jolanou, Magdou a bratom Gejzom. Ľudovú chlapčenskú evanjelickú školu začal navštevovať ako päťročný na jeseň roku 1898 (v areáli evanjelického kostola a fary). Po štvrtom ročníku ako deväťročný začína v roku 1902 študovať na Nižšom štvorročnom evanjelickom gymnáziu v Banskej Bystrici (na dnešnej Skuteckého ulici). Na Vyššom kráľovskom katolíckom gymnáziu v Banskej Bystrici (dnes námestie Štefana Moyzesa) absolvoval 5. – 8. ročník ukončený maturitnou skúškou roku 1910. Počas gymnaziálnych štúdií sa oboznamuje so základmi rôznych stavbárskych profesií na stavbách realizovaných otcovou firmou a získava výučné listy z murárstva, tesárstva a u kamenárskej firmy výučný list z kamenárstva.',
        'Učí sa kresliť u maliara Dominika Skuteckého a začína fotografovať, čo mu zostalo ako záľuba i predpoklad profesijného úspechu na celý život. Po maturite sa so spolužiakom a priateľom z gymnázia Júliusom Petrikovičom plaví v úlohe kadeta na lodi námorného kapitána Júliusa Thurzu z rumunskej Braily cez Čierne a Stredozemné more, okolo Gibraltáru, po Baltickom mori až do Holandska. Ako najstarší syn sa na žiadosť otca hlási na Kráľovskú technickú univerzitu v Budapešti a úspešne štúdium v júni 1914 absolvuje. Počas vysokoškolských štúdií navrhuje v rokoch 1912 – 1913 kaplnku v kúpeľnom mestečku Vyhne v Štiavnických vrchoch. Stavbu realizovala firma otca Juraja Hudeca a bola vysvätená dva dni pred vypuknutím 1. svetovej vojny dňa 26. júla 1914.',
    ]
};

const HudecChildhoodPage = () => {
    return (
        <Blog data={blog} />
    );
};

export default HudecChildhoodPage;