import React from "react";
import Blog from "../../components/blog/blog";

const blog = {
    title: 'Banská Bystrica - Šanghaj',
    subTitle: '9. kapitola - Banská Bystrica – Šanghaj – Lugano – Berkeley',
    eyeCatch: 'Banská Bystrica – Šanghaj – Lugano – Berkeley“',
    chapters: [
        'Legenda:',
        'Miesta života architekta L. E. Hudeca:',
        'Banská Bystrica (1893 – 1914)',
        'Šanghaj (1918 – 1947)',
        'Lugano (1947 – 1948)',
        'Berkeley/USA (1948 – 1958)',
        'Cesty architekta L. E. Hudeca:',
        '1. Cesty po Slovensku - Banská Bystrica, Oravský Hrad, Slovenská Ľupča, Spišská Kapitula',
        '2. Cesta po maturite okolo Európy (1910) - Budapešť (Dunaj), Bospor, Istanbul (Jadran), Gibraltár (okolo pevniny), Rotterdam (vlak), Berlín, Banská Bystrica',
        '2. Cesta po maturite okolo Európy (1910) - Budapešť (Dunaj), Bospor, Istanbul (Jadran), Gibraltár (okolo pevniny), Rotterdam (vlak), Berlín, Banská Bystrica',
        '3. Vysokoškolské štúdiá (1910 – 1914) - Banská Bystrica, Budapešť',
        '4. Vysokoškolská exkurzia (1913) - Budapešť, Norimberg',
        '5. Vysokoškolská exkurzia (1914) - Budapešť, Boloňa',
        '6. Cesta na front 1. sv vojny (1914 – 1916) - Banská Bystrica, Debrecín, Poľsko',
        '7. Cesta zajatia (1916 – 1918) - Kyjev, Moskva, Chabarovsk, Krasna Rečka',
        '8. Cesta úteku (1918) - Chabarovsk, Hilok, Charbin, Šanghaj',
        '9. Svadobná cesta (1922) - Šanghaj, Peking, Čínsky múr',
        '10. Cesta do Európy (1921) - Šanghaj, Budapešť, Banská Bystrica',
        '11. Cesta do Európy (1922) - Šanghaj, Banská Bystrica, Banská Štiavnica, Praha, Mníchov',
        '12. Cesta do New Yorku (1929 – 1932) - Šanghaj, New York',
        '13. Pobyty u architekta Antonina Reymonda (1932, 1935 – 1941) - Šanghaj, Karuizawa',
        '14. Zaoceánske plavby (1939) - Šanghaj, Suezský prieplav, Budapešť, New York (EXPO 1939), Seattle, Viktória (Kanada)',
        '15. Odchod do Európy (1947) - Šanghaj, Hong Kong, Manilla, Singapur, Colombo, Suezský prieplav, Janov, Livorno, Janov, Lugano',
        '16. Odchod do USA (1948) - Lugano, New York, Berkeley',
        '17. Cesta do Vatikánu (1953) - Berkeley, Vatikán',
        '18. Cesty do Európy - Nemecko, Švajčiarsko, Švédsko',
        '19. Cesty do Kanady - Viktória',
        '20. Posledná cesta L. E. Hudeca (1970) - Berkeley, Banská Bystrica',
    ]
};

const HudecShanghai2Page = () => {
    return (
        <Blog data={blog} />
    );
};

export default HudecShanghai2Page;