import React from "react";
import Blog from "../../components/blog/blog";

const blog = {
    title: 'Európa - Šanghaj - USA',
    subTitle: '13. kapitola - Európa na ceste Šanghaj – USA',
    eyeCatch: 'Európa na ceste Šanghaj – USA',
    chapters: [
        'Od marca 1947 rodina bývala v Švajčiarsku, v prenajatom dome v mestskej časti Lugana – Ruvigliana. Ich ďalší cestovný plán ešte nebol vykryštalizovaný. L. E. Hudec by bol rád ostal v tejto časti Európy s možnosťou obojstranných návštev členov rodiny z bývalej rodnej zeme, ako aj jeho návštev „domova“. Politická situácia Európy však ešte stále nebola jasná. L. E. Hudec bol rád, že tento čas mohol využiť spôsobom, po ktorom už dávno túžil. Spojil dva svoje dávne záujmy, archeológiu a teológiu. V oboch odboroch získal vlastným štúdiom dôkladné vedomosti. Jeho cieľom bol Rím, kde si mohol v tomto roku prezrieť Baziliku sv. Petra a osobne sa zúčastniť na archeologickom výskume pod ňou. Pápež Pius II. ho poctil osobným pozvaním, osobným rozhovorom a obdaroval ho (aj jeho manželku Gizelu) generálnou odpustkovou listinou, aj keď bol evanjelikom. Ocenil tým jeho pomoc a staviteľskú činnosť v Šanghaji pre katolícke pospolitosti, kostoly, kláštory a školy.',
        'L. E. Hudeca otázka výskumu v podzemí pápežskej baziliky zamestnávala aj naďalej a v roku 1952 zverejnil štúdiu o tejto téme v časopise Journal of Bible and Religion (Vol. 20, No. 1. (Jan., 1952), pp. 13 - 18. Oxford University Press). Neskôr prednášal na viacerých amerických univerzitách. Archeologické výskumy a vyhodnocovanie ich výsledkov sa dlho preťahovali a až v roku 1968 pápež Pavol VI. potvrdil vierohodnosť nálezov hrobu apoštola sv. Petra.',
        'Medzitým L. E. Hudec rozhodol o mieste pre ďalší pobyt svojej rodiny. Udalosti v Československu a Maďarsku v roku 1948 ho utvrdili v názore, že komunistický totalitný režim sa v Európe rozširuje. Chcel byť od neho čo najďalej. 23. júna 1948 vstupuje na pôdu USA a usadzuje sa v meste Berkeley v Kalifornii.'
    ]
};

const Chapter13Page = () => {
    return (
        <Blog data={blog} />
    );
};

export default Chapter13Page;