import React from "react";
import Blog from "../../components/blog/blog";

const blog = {
    title: 'Šanghaj L. E. Hudeca',
    subTitle: '10. kapitola - Šanghaj L. E. Hudeca (1918 – 1947)',
    eyeCatch: 'Šanghaj L. E. Hudeca (1918 – 1947)',
    chapters: [
        'Dňa 11. novembra 1918 sa skončila 1. svetová vojna, v čase, keď L. E. Hudec prišiel do Šanghaja. Od roku 1919 sa podpisuje menom Hudec, respektíve Wu-Ta-Kche v čínštine. Prenajíma si izbu na Hart Road (dnes Changde Road) a začína pracovať v ateliéri amerického architekta R. A. Curryho. Zakrátko sa spoznáva s Gizelou Meyer (1902 – 2004), s ktorou sa v roku 1922 zosobášil.',
        'Gizela Isabela Meyer pochádzala z nemecko-škótskej rodiny otca Carla Theodora Meyera z Brém a mamy Tisdall zo Škótska. V rodine sa rozprávalo anglicky a hlavne nemecky. L. E. Hudec rozprával mnohými jazykmi, popri slovenčine aj maďarsky, poľsky, ukrajinsky, rusky, anglicky, nemecky, francúzsky a taliansky. Čínsky sa nenaučil.',
        'Z manželstva sa im narodili tri deti Martin, Theo a dcéra Alessa. Počas 29 rokov strávených v Šanghaji bývali na troch miestach (Vila Sun Ke, Vila na Columbia Road a rezidencia Hubertus Court). V každom dome mal pracovňu, ktorá bola jeho svetom.',
        'L. E. Hudec nezaprel svojho podnikateľského ducha a s nástupom filmového priemyslu vybudoval tri kiná a stal sa vlastníkom dvoch nájomných domov a lukratívnych pozemkov na brehu mora.',
        'Počas vojny, od roku 1942, bol honorárnym konzulom Maďarska. Obyvateľom Maďarska napomáhal prekonávať strasti vojnových rokov. Listom prejavil nesúhlas s politickým vývojom v Maďarsku a dňa 18. októbra 1944 svoju diplomatickú misiu ukončil. V roku 1947 tajne opustil aj s rodinou Šanghaj, v dôsledku čoho prišiel o celý svoj majetok.',
        'Bol vášnivým fotografom a filmárom. Vlastnil fotoaparát Rolleiflex 6x6. V roku 1927 si obstaral prvú 16 mm kameru. Fotografoval všetko vycibreným pohľadom a vkusom, čoho dôkazom sú jeho rodinné fotografie, komponované zátišia a albumy z ciest. Športoval a choval domáce zvieratá. Bol čitateľom odbornej a technickej a inej literatúry. Na sklonku života sa venoval drevorezbárstvu. Zaujímal sa o archeológiu a teológiu raného kresťanstva. Vlastnil Bibliu v 65 jazykoch. Hĺbka jeho viery bola jeho trinástou komnatou. Ani jedno z jeho detí sa profesijne nevenovalo architektúre. Prejavil sklamanie, že ani jedno z jeho detí neprejavovalo náklonnosť k náboženstvu.',
        'Rodina, priatelia a spoločnosť architekta L. E. Hudeca',
        'Ladislav Hudec sa staral o dve rodiny – o svoju vlastnú od jej založenia až po koniec svojho života a o svoju pôvodnú, čo považoval za svoju prirodzenú povinnosť po smrti otca. Členov svojej pôvodnej rodiny uchránil od ťarchy veľkej podlžnosti voči Československej republike (krach podniku Hugyecz – Rosenauer) a postaral sa o ich životné minimum v časoch, keď to potrebovali. Ich korešpondencia bola permanentná, prerušená iba v poslednom období 2. svetovej vojny.',
        'Volal ich k sebe – podobne ako aj svojich priateľov. Z rodiny pozvanie prijala sestra Magda v roku 1926, pracovala v Šanghaji ako zdravotná sestra a v tomto odbore tam ukončila svoje štúdiá. Do Európy sa vrátila v roku 1930.',
        'Príchodu jeho najmladšieho súrodenca Gejzu patrí samostatná kapitola (1930 – 1933). Ostatných členov rodiny osobne navštevoval vždy, keď bol v Európe a keď to bolo možné. Svojich priateľov, vrstovníkov, či z čias vojny alebo ešte z Banskej Bystrice, s radosťou prijal a ak s tým súhlasili, taktiež zamestnal.',
        'Prvým bol architekt Gabriel Schreiber, ktorý s ním pracoval vo firme Rowlanda Curryho (v rokoch 1918 – 1920). Banskobystričan a architekt z rovnakého ročníka univerzity v Budapešti Vojtech Holesch ho navštívil z Bratislavy. Pracoval u neho aj Oskar Holesch z inej vetvy Holeschovcov a prebýval jeho brat, maliar Dionýz Holesch (1936). Hudec u neho objednal portréty svojich svokrovcov a umelec tak mohol pokračovať vo svojej ázijskej ceste. V tridsiatych rokoch zamestnal Ferdinanda Skuteckého, ktorý bol právnikom, ekonómom a synom maliara Dominika Skuteckého. Počet jeho návštevníkov a ľudí, ktorým pomohol, bol však určite mnohonásobne vyšší.',
        'O jeho spoločenských aktivitách v Šanghaji sa dozvedáme z vlastného životopisu, ktorý vyhotovil k žiadosti o americké občianstvo. Mal vysvetliť, prečo bol členom rôznych, aj nemeckých klubov v Šanghaji. Hudec bol členom klubov German Sport Abteilung, Cercle Sportif, Huangjao Golf Club, Circolo Italiano, German Garden Club a ďalších, ktoré nemali politický charakter a plnili rekreačnú a športovú funkciu – mali aj určitú spoločenskú úlohu. Hodnotiaci americkí úradníci prijali jeho vysvetlenia a 29. novembra 1951 mu udelili americké štátne občianstvo. Veľa času trávil v spoločnosti predstaviteľov kresťanských cirkví, čo súviselo aj so stavbou kostolov a cirkevných škôl.',
    ]
};

const HudecShanghai3Page = () => {
    return (
        <Blog data={blog} />
    );
};

export default HudecShanghai3Page;