import React from "react";
import Header from "../header";
import Footer from "../footer";

const Template = ({ children }) => {
    return (
        <>
            <Header />
            {children}
            <Footer />
        </>
    );
};

export default Template;