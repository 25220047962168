import React from "react";
import archCover from '../images/media/arch-obalka.jpg';
import asbCover from '../images/media/asb-obalka.jpg';
import memorandum from '../images/media/projekt-memorandum.jpg';
import zivot from '../images/media/zivot.jpg';
import bystricoviny from '../images/media/bystricoviny.jpg';
import earch from '../images/media/earch.png';
import ta3 from '../images/media/TA3.png';
import archinfo from '../images/media/archinfo.png';

const MediaPage = () => {
    return (
        <main>
            <section className="exhibitionBlock pt-6 pb-6 pt-md-9 pb-md-9 pt-lg-11 pb-lg-13 pt-xl-20 pb-xl-21">
                <div className="container">
                    <header className="topHeadingHead text-center mb-6 mb-lg-9 mb-xl-12">
                        <div className="row">
                            <div className="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                                <h1 className="h1Large mb-4">Napísali o nás</h1>
                            </div>
                        </div>
                    </header>
                    <div className="row">
                        <div className="col-12 col-md-3">
                            <article className="currExibitColumn mb-6 mb-lg-9 mb-xl-14 mx-auto">
                                <div className="imgHolder mb-3 mb-lg-6">
                                    <a href="https://www.lehudec.org/images/lehudec/onas/arch-6-19-hudec.pdf" target="_blank">
                                        <img src={archCover} className="img-fluid w-100 d-block grayscaled" alt="image description" />
                                    </a>
                                </div>
                                <h2 className="mb-3">
                                    <a href="https://www.lehudec.org/images/lehudec/onas/arch-6-19-hudec.pdf" target="_blank">ARCH</a>
                                </h2>
                            </article>
                        </div>
                        <div className="col-12 col-md-3">
                            <article className="currExibitColumn mb-6 mb-lg-9 mb-xl-14 mx-auto">
                                <div className="imgHolder mb-3 mb-lg-6">
                                    <a href="https://www.lehudec.org/images/lehudec/onas/asb.pdf" target="_blank">
                                        <img src={asbCover} className="img-fluid w-100 d-block grayscaled" alt="image description" />
                                    </a>
                                </div>
                                <h2 className="mb-3">
                                    <a href="https://www.lehudec.org/images/lehudec/onas/asb.pdf" target="_blank">ASB Architektúra</a>
                                </h2>
                            </article>
                        </div>
                        <div className="col-12 col-md-3">
                            <article className="currExibitColumn mb-6 mb-lg-9 mb-xl-14 mx-auto">
                                <div className="imgHolder mb-3 mb-lg-6">
                                    <a href="https://www.lehudec.org/images/lehudec/onas/projekt_6_18_memorandum.pdf" target="_blank">
                                        <img src={memorandum} className="img-fluid w-100 d-block grayscaled" alt="image description" />
                                    </a>
                                </div>
                                <h2 className="mb-3">
                                    <a href="https://www.lehudec.org/images/lehudec/onas/projekt_6_18_memorandum.pdf" target="_blank">Projekt memorandum</a>
                                </h2>
                            </article>
                        </div>
                        <div className="col-12 col-md-3">
                            <article className="currExibitColumn mb-6 mb-lg-9 mb-xl-14 mx-auto">
                                <div className="imgHolder mb-3 mb-lg-6">
                                    <a href="https://www.lehudec.org/images/lehudec/onas/hudec.pdf" target="_blank">
                                        <img src={zivot} className="img-fluid w-100 d-block grayscaled" alt="image description" />
                                    </a>
                                </div>
                                <h2 className="mb-3">
                                    <a href="https://www.lehudec.org/images/lehudec/onas/hudec.pdf" target="_blank">Život</a>
                                </h2>
                            </article>
                        </div>
                    </div>
                    <header className="topHeadingHead text-center mb-6 mb-lg-9 mb-xl-12">
                        <div className="row">
                            <div className="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                                <h1 className="h1Large mb-4">Média o nás</h1>
                            </div>
                        </div>
                    </header>
                    <div className="row">
                        <div className="col-12 col-md-3">
                            <article className="currExibitColumn mb-6 mb-lg-9 mb-xl-14 mx-auto">
                                <div className="imgHolder mb-3 mb-lg-6">
                                    <a href="https://www.earch.cz/udalosti/clanek/architekt-ktery-postavil-sanghaj-banska-bystrica-zahajuje-vystavu-venovanou-svemu-slavnemu-rodakovi" target="_blank">
                                        <img src={earch} className="img-fluid w-100 d-block grayscaled" alt="image description" />
                                    </a>
                                </div>
                                <h2 className="mb-3">
                                    <a href="https://www.earch.cz/udalosti/clanek/architekt-ktery-postavil-sanghaj-banska-bystrica-zahajuje-vystavu-venovanou-svemu-slavnemu-rodakovi" target="_blank">EARCH_01</a>
                                </h2>
                                <span className="d-block cecTime text-gray777">Architekt, který postavil Šanghaj</span>
                            </article>
                        </div>
                        <div className="col-12 col-md-3">
                            <article className="currExibitColumn mb-6 mb-lg-9 mb-xl-14 mx-auto">
                                <div className="imgHolder mb-3 mb-lg-6">
                                    <a href="https://www.archinfo.sk/diskusia/blog/stala-expozicia-venovana-architektovi-l-e-hudecovi-banska-bystrica-sanghaj-bola-vcera-slavnostne-otvorena.html" target="_blank">
                                        <img src={archinfo} className="img-fluid w-100 d-block grayscaled" alt="image description" />
                                    </a>
                                </div>
                                <h2 className="mb-3">
                                    <a href="https://www.archinfo.sk/diskusia/blog/stala-expozicia-venovana-architektovi-l-e-hudecovi-banska-bystrica-sanghaj-bola-vcera-slavnostne-otvorena.html" target="_blank">ARCHINFO_01</a>
                                </h2>
                                <span className="d-block cecTime text-gray777">Stála expozícia venovaná architektovi L. E. Hudecovi „Banská Bystrica - Šanghaj“ bola včera slávnostne otvorená</span>
                            </article>
                        </div>
                        <div className="col-12 col-md-3">
                            <article className="currExibitColumn mb-6 mb-lg-9 mb-xl-14 mx-auto">
                                <div className="imgHolder mb-3 mb-lg-6">
                                    <a href="https://www.archinfo.sk/kalendarium/architekt-l-e-hudec-banska-bystrica-sanghaj.html" target="_blank">
                                        <img src={archinfo} className="img-fluid w-100 d-block grayscaled" alt="image description" />
                                    </a>
                                </div>
                                <h2 className="mb-3">
                                    <a href="https://www.archinfo.sk/kalendarium/architekt-l-e-hudec-banska-bystrica-sanghaj.html" target="_blank">ARCHINFO_02</a>
                                </h2>
                                <span className="d-block cecTime text-gray777">Architekt L. E. Hudec „Banská Bystrica - Šanghaj“</span>
                            </article>
                        </div>
                        <div className="col-12 col-md-3">
                            <article className="currExibitColumn mb-6 mb-lg-9 mb-xl-14 mx-auto">
                                <div className="imgHolder mb-3 mb-lg-6">
                                    <a href="https://www.archinfo.sk/diela/rekonstrukcia-a-obnova/stala-expozicia-venovana-l-e-hudecovi.html" target="_blank">
                                        <img src={archinfo} className="img-fluid w-100 d-block grayscaled" alt="image description" />
                                    </a>
                                </div>
                                <h2 className="mb-3">
                                    <a href="https://www.archinfo.sk/diela/rekonstrukcia-a-obnova/stala-expozicia-venovana-l-e-hudecovi.html" target="_blank">ARCHINFO_03</a>
                                </h2>
                                <span className="d-block cecTime text-gray777">
                                    Rekonštrukcia a obnova - Architekt L.E. Hudec - Banská Bystrica - Stála expozícia
                                </span>
                            </article>
                        </div>
                        <div className="col-12 col-md-3">
                            <article className="currExibitColumn mb-6 mb-lg-9 mb-xl-14 mx-auto">
                                <div className="imgHolder mb-3 mb-lg-6">
                                    <a href="https://www.bystricoviny.sk/vystava-2/stala-expozicia-ladislava-juraja-hudecovcov-banskej-bystrici/?autoplay=1#" target="_blank">
                                        <img src={bystricoviny} className="img-fluid w-100 d-block grayscaled" alt="image description" />
                                    </a>
                                </div>
                                <h2 className="mb-3">
                                    <a href="https://www.bystricoviny.sk/vystava-2/stala-expozicia-ladislava-juraja-hudecovcov-banskej-bystrici/?autoplay=1#" target="_blank">Bystricoviny</a>
                                </h2>
                                <span className="d-block cecTime text-gray777">Stála expozícia Ladislava a Juraja Hudecovcov v Banskej Bystrici</span>
                            </article>
                        </div>
                        <div className="col-12 col-md-3">
                            <article className="currExibitColumn mb-6 mb-lg-9 mb-xl-14 mx-auto">
                                <div className="imgHolder mb-3 mb-lg-6">
                                    <a href="https://www.ta3.com/clanok/147212/banska-bystrica-ma-maly-sanghaj-uctili-si-slavneho-rodaka" target="_blank">
                                        <img src={ta3} className="img-fluid w-100 d-block grayscaled" alt="image description" />
                                    </a>
                                </div>
                                <h2 className="mb-3">
                                    <a href="https://www.ta3.com/clanok/147212/banska-bystrica-ma-maly-sanghaj-uctili-si-slavneho-rodaka" target="_blank">TA3</a>
                                </h2>
                                <span className="d-block cecTime text-gray777">Banská Bystrica má malý Šanghaj - uctili si slávneho rodáka</span>
                            </article>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default MediaPage;