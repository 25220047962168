import React from "react";
import Blog from "../../components/blog/blog";

const blog = {
    title: 'Banská Bystrica - Šanghaj',
    subTitle: '2. kapitola - Banská Bystrica - Šanghaj',
    eyeCatch: 'Banská Bystrica – Šanghaj“',
    chapters: [
        'Banská Bystrica, slobodné kráľovské banské mesto od roku 1255, leží v srdci stredného Slovenska v oblasti bohatej na rudné ložiská. Začiatky ťažby kovov siahajú podľa archeologických nálezov ďalej ako prvé písomné zmienky. Prvá konjunktúra ťažby a spracovania medi, ktorá predstavovala na rozdiel od drahých kovov strategickú surovinu, sa začala začiatkom 14. storočia. Od roku 1496 fungoval v Banskej Bystrici medzinárodne známy ranokapitalistický mediarsky podnik založený Jánom Thurzom z Betlanoviec a Jakubom Fuggerom z Augsburgu Neusohler Kupferhandel, do roku 1546 známy aj ako Ungarische Handel, s množstvom baní a hút v okolí mesta, v ktorom sa spracovávala meď do finálnej podoby. Kvalita medi zaručovala vývoz do mnohých krajín Európy i na iné kontinenty. Rozsiahly mediarsky podnik fungoval aj v ďalších storočiach po transformácii na banskú komoru v Banskej Bystrici vďaka unikátnym a progresívnym postupom získavania skoro stopercentnej medi. Kontinuita výroby medi typickej pre Banskú Bystricu spolu s existenciou historických výrobných areálov trvala takmer do konca 20. storočia.',
        'Mesto Banská Bystrica s desaťtisíc obyvateľmi sa na prelome 19. a 20. storočia vymaňovala z úzkeho priestoru stredovekého mesta a jeho fortifikačného systému tvoreného hradbami a mestskými bránami. Ich asanovaním sa utvárali podmienky pre stavebný rozvoj mesta. Vznikali nové ulice, mestské štvrte, priemyselné podniky, parný a valcový mlyn, nábytková továreň, sódovkáreň, pivovar, likérka, zápalkáreň, kamenárske a stavebné firmy, atď. Prudký stavebný rozvoj prial práci architektov z celého Uhorska a miestnym stavebným firmám. Stavba významných verejných budov (budovy súdu, škôl, bánk, mostov, nábreží) bola financovaná štátom, mestom i bohatými mecenášmi. Mesto sa elektrifikovalo. Stavba železničnej trate umožnila lepšie spojenie Banskej Bystrice so svetom.',
        'Banská Bystrica je po sto rokoch, keď sa k nej pripojili viaceré okolité obce, mestom s počtom obyvateľov približne 80-tisíc. Je sídlom okresu i Stredoslovenského kraja. Okrem početných odborných stredných škôl má aj Univerzitu Mateja Bela s niekoľkými fakultami i Akadémiu umení. Preto v meste žijú tisícky študentov. Banská Bystrica s viacerými historickými cennými kostolmi je i sídlom rímsko-katolíckeho biskupstva, Štátnej opery aj Športového klubu Dukla Banská Bystrica. Zdravotnícku starostlivosť poskytuje okresná poliklinika, krajská nemocnica a Ústav srdcovocievnych chorôb. Vzhľadom na prekrásne prírodné prostredie a bohatú históriu, ako jedno z banských miest, má veľký potenciál v oblasti rozvoja turizmu. Stavebný vývoj sa uberá nie vždy k prospechu a vzhľadu mesta a nie vždy s úctou k jeho historickým hodnotám.',
        'Aj keď je Banská Bystrica v porovnaní so Šanghajom veľmi malé mesto, je to miesto, v ktorom sa L. E. Hudec narodil, vyrástol a ktoré mal celý svoj život tak rád, že sa doňho chcel vrátiť. Mohol tak urobiť až dvanásť rokov po smrti v roku 1970, keď urnu s jeho pozostatkami uložili do rodinnej hrobky na evanjelickom cintoríne.',
        '<strong>Šanghaj</strong>',
        'V čase príchodu L. E. Hudeca mala čínska metropola Šanghaj 2 500 000 obyvateľov. Šanghaj bol miestom s najrozličnejšími etnickými skupinami. Cez ópiové vojny a Európanmi získanými územiami bola metropola rozdelená do koncesijných častí: medzinárodnú a francúzsku so svojimi vlastnými teritoriálnymi územiami a etnikom. Obraz európskeho blahobytu a úspechu bol v živom kontraste s chudobou a častými vojenskými prevratmi traumatizovanými pôvodnými obyvateľmi.',
        'Piata najväčšia metropola sveta sa stala útočiskom aj pre L. E. Hudeca. Architekt nespadal pod žiadnu koncesiu. V 30. rokoch nastával rozmach metropoly na rieke Whangpoo (dnes Huangpu) aj napriek vojnou zdecimovanému svetu. Svojou rozmanitosťou mesto dostalo prívlastok „Paríž orientu“, ale aj „Sin City“ s prekvitajúcim ekonomickým a spoločenským životom.',
        'Svetové prvenstvá si Šanghaj udržal podnes. Najväčšie čínske mesto, v súčasnosti s 24 183 300 obyvateľmi a čulým ekonomickým ruchom, zažíva novú éru stavebného rozmachu. V posledných tridsiatich rokoch v metropole nastal ohromný stavebný boom, ktorý na nepoznanie zmenil panorámu mesta. Panoráma metropoly je tvorená siluetou 632-metrovej budovy Shanghai Tower (2014) od architektonickej spoločnosti Gensler popri výškových budovách od architektov Kohn Pederson Fox - Shanghai World Financial Center (2008) a Jin Mao Tower (1999) od Adriana Smitha, ktoré sú umocnené ikonickou televíznou vežou Oriental Pearl Tower (1994).V spleti výškových budov je však práve tá prvá od architekta L. E. Hudeca.'
    ]
};

const HudecShanghaiPage = () => {
    return (
        <Blog data={blog} />
    );
};

export default HudecShanghaiPage;