import React from "react";
import {Link} from "react-router-dom";

const Page404 = () => {
    return (
        <main>
            <article className="pageNotFoundBlock hdIsVii d-flex w-100 text-center">
                <div className="lbAlignWrap d-flex align-items-center w-100">
                    <div className="container py-6">
                        <div className="row">
                            <div className="col-12 col-md-8 offset-md-2 col-xl-6 offset-xl-3">
                                <div className="extraWrap px-md-12">
                                    <i className="icnWrap d-block mb-5">
                                        <img src="images/icn16.svg" width="72" height="73" alt="icon" />
                                    </i>
                                    <h2 className="mb-3">Stránka nebola nájdená</h2>
                                    <p className="mb-7">Vyzerá to tak, že zadaná adresa neexistuje. Pokračujte na hlavnú stránku.</p>
                                    <Link to="/" className="btn btn-secondary text-uppercase btnMdMinWidth">Späť domov</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </main>
    );
};

export default Page404;