import React from "react";
import Blog from "../../components/blog/blog";

const blog = {
    title: 'História rodiny',
    subTitle: '3. Kapitola - História rodiny',
    eyeCatch: 'História rodiny',
    chapters: [
        'Ladislav Eduard Hudec bol potomkom dvoch starobylých slovenských rodov Hudecovcov a Škultétyovcov. Hudecovci boli široko rozvetveným rodom mlynárov, korí pôsobili prevažne vo zvolenskej stolici, v blízkom okolí miest Banská Bystrica a Zvolen po mnoho generácií. Aj šiesti praotcovia L. E. Hudeca boli mlynármi. V 19. storočí ubúdalo vodných mlynov vplyvom priemyselnej revolúcie (existencia veľkých parných a valcových mlynov), a preto sa otec L. E. Hudeca dal na inú profesijnú dráhu. Stal sa staviteľom. Matka L. E. Hudeca Pavla Škultétyová pochádzala zo zemianskeho rodu Škultétyovcov z Dolnej Lehoty. Jej otec, otcov brat, starý otec a aj ďalší z rodiny boli evanjelickými kňazmi, cirkevnými funkcionármi, ale aj národno-kultúrnymi dejateľmi. História oboch rodov mala veľký vplyv na formovanie osobnosti budúceho architekta.',
        'V roku 2011 sa podarilo objaviť rodný dom, preskúmať pôvod a históriu rodiny, z ktorej architekt pochádzal. Pri výročí jeho narodenia 8. 1. 2012 bola odhalená pamätná tabuľa, na rodnom dome architekta, na Bakossovej ulici v Banskej Bystrici.',
        '<strong>Súrodenci Ladislava Eduarda Hudeca</strong>',
        'Najstaršia sestra L. E. Hudeca <strong>Edita</strong> sa narodila 17. augusta 1894. Po zápale mozgových blán v detstve stratila sluch. Bola vzdelávaná v ústave pre hluchonemé deti vo Váci, kde si našla aj manžela, tiež sluchovo postihnutého profesora kreslenia Edmunda Gerstnera. Ovládala okrem domácich jazykov angličtinu, esperanto a dobre tancovala. Mala dve dcéry a hoci po narodení prvej stratila aj zrak, nestratila chuť do života, naučila sa Braillovo písmo. Umrela v 73. roku života u dcéry v Anglicku, odkiaľ jej popol preniesli do rodného mesta a uložili do hrobky Hudecovcov na evanjelickom cintoríne.',
        'Druhá sestra <strong>Valéria Frída Olívia</strong> (používala meno Lívia) sa narodila 30. mája 1897. Po smrti otca a matky prevzala na seba časť zodpovednosti za rodinu. Vydala sa až po presťahovaní zvyšku rodiny do Budapešti za vdovca Jána Ballyho, s ktorým mala dvoch synov a jednu dcéru. Umrela 88-ročná. Je pochovaná spolu s manželom na evanjelickom cintoríne v Banskej Bystrici v rodinnej hrobke.',
        'Tretia sestra <strong>Jolana Marta</strong> sa narodila 8. mája 1900. Vydala sa tesne pred skončením 1. svetovej vojny za profesora evanjelického gymnázia v Banskej Bystrici Dr. Štefana Jánossiho. Po narodení prvého syna sa rodina presťahovala do Budapešti, kde Dr. Jánossi mohol pokračovať v začatej pedagogickej kariére. V Budapešti sa narodili ešte dvaja synovia a jedna dcéra. V tejto rodine staršej sestry našiel brat Gejza po strate rodičov druhý domov. Potomstvo Jolany Hudecovej-Jánossiovej je zo všetkých súrodencov najpočetnejšie a sú medzi nimi významní jedinci - dizajnéri, spisovatelia a architekti. Jolana sa dožila 84 rokov a telesné pozostatky jej i jej manžela boli uložené do rodinnej hrobky na evanjelickom cintoríne.',
        'Posledná štvrtá sestra <strong>Jurajka Valéria Frederika Magdaléna</strong> (používala meno Magda) sa narodila 28. decembra 1904. Už ako dospelá odišla k bratovi Ladislavovi do Šanghaja, kde zmaturovala a zložila skúšky z ošetrovateľstva. Potom pracovala ako ošetrovateľka vo veľkej nemocnici v Šanghaji, istý čas v Nemocnici sv. Karola v Londýne a nakoniec ako učiteľka v Novom Sade. Vydala sa 45-ročná za vdovca Michala Horváta do Vasárosnamény v Maďarsku. Ako jediná zo šiestich súrodencov nie je pochovaná v rodinnej hrobke.',
        '<strong>Gejza Fridrich Juraj</strong>, najmladší súrodenec L. E. Hudeca, sa narodil 22. februára 1907 ako jediný zo súrodencov v dome, ktorý postavil otec Juraj pre vlastnú rodinu. Ľudovú školu a prvé ročníky gymnázia absolvoval v rodnom meste, rok v Bratislave a posledné roky ukončené maturitou v Budapešti. Niekoľko rokov študoval na Technickej univerzite v Budapešti a absolvoval vojenskú službu v Československej armáde. Po ročnom pobyte v USA odcestoval za bratom do Šanghaja, kde prežil iba dva roky. Po opakovanej brušnej operácii zomrel 23. februára 1933. Po roku urnu s jeho pozostatkami ako prvého z detí Juraja Hudeca uložili do rodinnej hrobky v Banskej Bystrici.',
    ]
};

const Chapter3Page = () => {
    return (
        <Blog data={blog} />
    );
};

export default Chapter3Page;