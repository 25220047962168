import React from "react";
import Blog from "../../components/blog/blog";

const blog = {
    title: 'Architekt-L.E.Hudec',
    subTitle: '1. kapitola - Architekt L. E. Hudec',
    eyeCatch: 'Architekt L. E. Hudec „Banská Bystrica – Šanghaj“',
    chapters: [
        'Výstavný projekt stálej expozície je venovaný osobnosti Ladislava Eduarda Hudeca, architekta svetového formátu, banskobystrického rodáka, ktorý svojím životom zaujal architektonické, ako aj celospoločenské publikum. Uznávaný architekt v Šanghaji a v zahraničí, ale málo známy na Slovensku. Významným priblížením osobnosti L. E. Hudeca bolo vytvorenie filmového dokumentu Muž, ktorý zmenil Šanghaj od režiséra Ladislava Kaboša, ktorý bol predstavený na svetovej výstave EXPO 2010 v Šanghaji.',
        'Architekt L. E. Hudec sa narodil v rodine mestského staviteľa Juraja Hudeca. Ladislavove detstvo bolo silne ovplyvnené prostredím Banskej Bystrice a osobnosťou jeho otca. Už počas gymnaziálnych štúdií získal výučné listy v stavebných profesiách. Rodinné zázemie a prostredie Banskej Bystrice podnietilo kreatívneho mladíka študovať odbor architektúra na univerzitnej pôde v Budapešti. Po úspešnom štúdiu nastalo zlomové obdobie. Jeho uplatnenie v architektúre bolo narušené vypuknutím 1. svetovej vojny, udalosťou, ktorá ho ovplyvnila na celý život. Ladislav po krátkom výcviku dobrovoľníkov nastúpil na front, kde sa uplatnil ako projektant, ale aj v bojovej línii, kde išlo o život. Túžba mladého človeka vrátiť sa domov bola znemožnená udalosťami na východnom fronte a dva a polročným zajatím na Sibíri. Svoje útočisko po prežitých vojnových strastiach našiel v čínskom Šanghaji. Mesto, ktoré malo byť prechodnou stanicou, sa stalo na 29 rokov architektovým domovom a najvýraznejšie sa zapísalo do života Banskobystričana.',
        'Výstavný projekt Architekt L. E. Hudec „Banská Bystrica – Šanghaj“ je koncipovaný a zameraný na architekta L. E. Hudeca. Má však presah aj k architektovej rodine, otcovi Jurajovi Hudecovi, súrodencom, rodinnému zázemiu, obdobiu štúdií a nespočetným cestám a záujmom. Výstava približuje nevyčerpateľný životný elán a kreatívny prístup architekta. Je zároveň pohľadom na miesta, ktoré L. E. Hudeca zásadne ovplyvnili, na Banskú Bystricu a Šanghaj. Geografickú vzdialenosť, historický a spoločenský vývoj a kultúrnu inakosť čínskej metropoly architekt zachytil v stovkách osobných listoch písaných rodine a priateľom. Do koncesií rozdelený Šanghaj svojím multikultúrnym prostredím vytváral priaznivé podmienky pre rozvoj stavebnej činnosti v spleti rôznorodosti štýlov a investičných zámerov. Tvorba L. E. Hudeca bola týmito okolnosťami silne ovplyvnená. Kreativita a dôslednosť, s ktorou pristupoval k práci sa opierala o nadobudnuté vysokoškolské vzdelanie, ale naznačovala aj jeho silného podnikateľského ducha. S jednotlivými klientmi nadväzoval osobné vzťahy. Počas 29-ročnej tvorby v Šanghaji navrhol okolo 400 projektov, ktoré dodnes vytvárajú charakter a jedinečnosť metropoly Šanghaj.',
        'L. E. Hudec sa oženil s dcérou nemeckého veľkopodnikateľa Gizelou Meyer, s ktorou mali dvoch synov Martina a Thea a dcéru Alessu. Počas šanghajskej životnej etapy architekta navštívila sestra Magda, brat Gejza a priatelia z mladosti. Architektov vytúžený návrat do rodnej krajiny mu bol opäť znemožnený a po krátkej prestávke v švajčiarskom Lugane našiel svoj nový domov v americkom štáte Kalifornia, kde na pobreží Golden Gate prežil posledných deväť rokov svojho života. Túžba po návrate domov sa L. E. Hudecovi splnila až v roku 1970, keď sa jeho životná púť skončila na evanjelickom cintoríne v rodnej Banskej Bystrici.',
        'V roku 2012 bola v Banskej Bystrici založená nezisková organizácia Centrum architekta L. E. Hudeca (C.A.L.E.H. n. o.), ktorá po knižnom diele pani Kláry Kubičkovej s kolektívom Po stopách architekta L. E. Hudeca pripravila prezentovaný výstavný projekt stálej expozície života a diela architekta L. E. Hudeca.'
    ],
    author: 'Rastislav Udžan',
    production: 'Centrum architekta L. E. Hudeca (C.A.L.E.H. n. o.)'
}

const HudecArchitectPage = () => {
    return (
        <Blog data={blog} />
    );
};

export default HudecArchitectPage;