import React from "react";

const Footer = () => {
    return (
        <footer id="pageFooter" className="bg-dark text-gray777 text-center pt-6 pb-3 pt-lg-11 pb-lg-9">
            <div className="container">
                <ul className="list-unstyled socialNetworks ftSocialNetworks d-flex flex-wrap justify-content-center mb-4">
                    <li>
                        <a href="https://www.facebook.com/CALEHbb" target="_blank">
                            <i className="fab fa-facebook-f"><span className="sr-only">facebook</span></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/caleh_bb/" target="_blank">
                            <i className="fab fa-instagram"><span className="sr-only">instagram</span></i>
                        </a>
                    </li>
                </ul>
                <p className="mb-1">
                    Pre návštevu či komentovanú prehliadku stačí zavolať na číslo
                </p>
                <p className="mb-4">
                    +421 902 712 756<br />
                    +421 905 800 775
                </p>
                <p className="mb-2">
                   Nájdete nás
                </p>
                <p className="mb-4">
                    na rohu Skuteckého a Kukučínovej ulice<br />
                    Skuteckého 22, Banská Bystrica (Späthova vila)
                </p>
                <p className="mb-1">
                    info@lehudec.org
                </p>
            </div>
        </footer>
    );
};

export default Footer;