import React from "react";
import introImg1 from '../images/space-wide.png';
import ExposurePreview from "../components/exposurePreview";
import Supporters from "../components/supporters";
import {Link} from "react-router-dom";
import IgFeed from "../components/igFeed";

const HomePage = () => {
    return (
        <main>
            <div className="bsSlidViv">
                <div>
                    <a href="https://my.matterport.com/show/?m=Znxu4e31CgB" target="_blank">
                        <section
                            className="introBannerBlock introBannerBlockVii hdIsVii w-100 text-center text-white position-relative d-flex bgCover"
                            style={{ backgroundImage: `url(${introImg1})` }}>
                        </section>
                    </a>
                </div>
            </div>
            <article className="exploreAboutBlock text-center pt-7 pb-7 pt-md-9 pb-md-9 pt-lg-15 pb-lg-16 pt-xl-22 pb-xl-23">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                            <div className="estStamp rounded-circle mx-auto d-flex align-items-center mb-6">
                                <div className="d-block w-100 p-3">
                                    <strong className="title d-block text-uppercase font-weight-normal">est.</strong>
                                    <hr className="my-2" />
                                        <strong className="subtitle d-block font-weight-normal">2012</strong>
                                </div>
                            </div>
                            <h2 className="h2Medium mb-5">Centrum architekta <br /> Ladislava Eduarda Hudeca</h2>
                            <div className="eabDescrText mb-6 mb-lg-10">
                                <p>Naše centrum sa venuje dokumentácii a uchovávaniu duchovných a kultúrnych
                                    hodnôt, ktoré po sebe zanechal banskobystrický rodák, architekt
                                    L.E.Hudec (1893-1958).</p>
                            </div>
                            <Link to="/o-nas" className="btn btn-secondary btnLgMinWidth">Viac o nás</Link>
                        </div>
                    </div>
                </div>
            </article>
            <ExposurePreview />
            <Supporters />
            <IgFeed />
        </main>
    );
};

export default HomePage;