import React from "react";
import Blog from "../../components/blog/blog";

const blog = {
    title: 'Tvorba L. E. Hudeca v Šanghaj',
    subTitle: '11. kapitola - Tvorba L. E. Hudeca v Šanghaji',
    eyeCatch: 'Tvorba L. E. Hudeca v Šanghaji',
    chapters: [
        'Profesijné podmienky v Šanghaji boli pre mladého architekta veľmi rozmanité. Počas 29 rokov strávených v metropole vytvoril okolo 400 projektov, z ktorých je mnoho zapísaných v zozname kultúrnych pamiatok (Shanghai Excellent Historical Building).',
        'L. E. Hudec dokonale pretavil v architektonickej profesii svoj podnikateľský talent a dokázal osloviť čínsku aj európsku klientelu zákazníkov.',
        'Najlepšie pozemky a parcely boli skúpené koncesionármi. Napriek nepriaznivým vstupom sa architekt vedel vysporiadať s nevhodným pozemkom a vyťažiť z neho maximum (Grand Theatre). Architekt svojím vrcholným dielom inšpiroval čínsko-amerického architekta I. M. Pei k štúdiu architektúry.',
        '„Architektúra a urbanizmus sú podľa môjho názoru neoddeliteľné. Dnešní architekti by sa mali zamýšľať návrhom totálneho prostredia.“ Ieoh Ming Pei (1917)',
        'Svoju profesionálnu kariéru začal u amerického architekta R. A. Curryho (11-B Nanking Road), tvorbou ovplyvnenou návratom ku klasickým slohom (Normandie Apartments, American Club, Union Building).',
        'Na pozícii kresliča dlho nepobudol a čoskoro navrhoval budovy samostatne a stal sa spoločníkom firmy R. A. Curryho. Oceňoval základ svojho akademického vzdelania. K projektom pristupoval osobitým prístupom Európana, ale pri zohľadnení všetkých klientských nárokov. S pomocou svokra zakladá 1. januára 1925 vlastnú firmu, čím sa oslobodil.',
        'Vo svojej práci aplikoval najnovšie technológie a stavebné trendy hlavne pri zakladaní stavieb, tvorivo pracoval s architektonickým detailom, vedel dokonale využiť jednotlivé architektonické štýly. V období rokov 1927 – 1937 nastáva stavebný rozmach a zlatá dekáda pre Šanghaj. V tomto období postavil väčšiu časť svojej tvorby. Disponoval rozsiahlou knižnicou architektonických monografií a technickej a inej literatúry. L. E. Hudec sa podľa vlastných slov cítil viac inžinierom-technikom ako architektom.',
        '<strong>Tvorba architekta v kancelárii R. A. Curryho:</strong>',
        '<strong>Vila - Ho Tung\'s Residence (1918 - 1920)</strong>',
        '457 North Shaanxi Road, Šanghaj',
        'L. E. Hudec projektoval budovu u architekta R. A. Curryho v neoklasicistickom štýle. Stavebné prevedenie dvojpodlažnej budovy bolo zabezpečené stavebníkmi S. Y. Chang a Hye Shing Kee. Prvotný zámer projektu bol iniciovaný Williamom Katzom, riaditeľom Shanghai Plant of Hoechst Chemical. V závere bola vila, postavená v internacionálnej koncesii, predaná podnikateľovi Robertovi Ho Tungovi, akcionárovi HSBC Bank, ktorý bol pre svoje kultúrne a podnikateľské aktivity v roku 1915 britským kráľom Georgeom V. pasovaný za rytiera.',
        'Estetický a citlivý prístup architekta v kombinácii s iónskymi stĺpmi, ktoré podopierajú balkóny, francúzskymi oknami a balustrádami ukončenou strechou, vytvára romantizujúcu atmosféru. Bohatá malebnosť sa prejavila aj v interiéri s obľúbeným špirálovým schodiskom s kovovým zábradlím, šachovnicovou bielo-čiernou mramorovou dlažbou a s bohato vyrezávaným dreveným nábytkom. Investícia za 47 000 $ tesne pred dokončením vyhorela a bola znovu postavená. Projekt bol príbuzný ďalšej realizácii L. E. Hudeca, vile Liu Jisheng. Po roku 1949 sa rodina podnikateľa odsťahovala a od roku 1958 sídli v budove štátna inštitúcia Shanghai Lexicographical Publishing House. Od roku 2005 je budova zapísaná v zozname chránených kultúrnych pamiatok (Shanghai Excellent Historical Building for Preservation).',
        '<strong>Nájomný dom - Normandie Apartments (1923 - 1926)</strong>',
        '1836 - 1858 Middle Huaihai Road, Šanghaj',
        'Obytná budova trojuholníkového pôdorysu vtesnaná v križovatke piatich ulíc francúzskej koncesie bola významným dielom L. E. Hudeca. Názov projektu Normandia bol pridelený investorskou spoločnosťou J. S. S., ktorá všetkým svojim projektom pripisovala názvy podľa francúzskych regiónov. Nárožná osempodlažná budova má pôdorys tvaru obráteného písmena E. Na prízemí sú obchodné prevádzky a priestranné vstupné a komunikačné priestory s výťahmi. Na každom podlaží je pätnásť až dvadsať bytových jednotiek so samostatnými obytnými priestormi pre služobníctvo. Jednotlivé bytové jednotky prehľadnej dispozície sú prístupné z centrálnej chodby orientovanej do nádvoria.',
        'Exponovanej fasáde v štýle francúzskej renesancie dominuje vysoký, kameňom obložený sokel cez prvé dve podlažia, ktorý v kontraste s tehlovou fasádou hlavnej hmoty a kamenným obložením posledného podlažia vytvára vyváženú kompozíciu. Materiálové prechody sú elegantne oddelené pásom balkónových balustrád v spodnej časti a subtílnym kovovým zábradlím na najvyššom podlaží. Tehlová fasáda je zjemnená ušľachtilým delením okenných otvorov a predsadených balkónov, ktoré boli použité prvýkrát pri obytnej výstavbe v Šanghaji. Budova bola prioritne určená pre vysokopostavených úradníkov zo zahraničia. Po roku 1946 budovu odkúpila nastupujúca vláda Kuomintangu (rezidencia Zhao Zukang). Časom bol objekt vyhľadávaný šanghajskou umeleckou elitou (herec Zhao Dan a herečka Qing Yi). Od roku 1995 je budova zapísaná v zozname chránených kultúrnych pamiatok (Shanghai Excellent Historical Building for Preservation).',
        '<strong>Tvorba architekta L. E. Hudeca vo vlastnom ateliéri:</strong>',
        '<strong>Nemocnica - Country Hospital (1923 - 1926)</strong>',
        '221 West Yan\'an Road, Šanghaj',
        'Nemocnica Country Hospital bola jedným z prvých dôležitých projektov architekta L. E. Hudeca v samostatnej kariére. Bola financovaná utajeným investorom. Dnes sa už vie, že to bol Charles Reyner, ktorý na tento projekt venoval 1 milión dolárov. Pre projekt bol vytýčený pozemok vo francúzskej koncesii s rozlohou 10 649 m2, z ktorých 2300 m2 tvorí nemocnica. Symetrická päťpodlažná budova navrhnutá v štýle talianskej renesancie je rozčlenená do pôdorysného tvaru písmena H. Čelná južná fasáda je rozčlenená do troch vertikálnych polí s arkádovým podlubím na prízemí s hlbokými lodžiami na poschodiach, ktoré sú zastrešené štítovými frontónmi.',
        'Budova pod klasickým vizuálom disponuje kreatívnym členením priestorov. Hotelovým efektom pôsobiace vstupné lobby so šachovnicovou čierno-bielou mramorovou podlahou a dreveným kazetovým stropom usmerňuje pacientov pri vstupe do jednotlivých krídel. Budova je určená pre 100 až 150 pacientov. Bol to prvý nemocničný objekt vybavený klimatizáciou (Swiss Sulzer Brothers Co.) na Ďalekom východe a výťahmi (Otis). Dnes je budova súčasťou nemocničného areálu Huadong Hospital a je úctyhodné, že v skupine výškových budov a nedostatku stavebných parciel sa zachovala záhrada s pôvodnými borovicovými a magnóliovými stromami. Podľa Shanghai Sunday Times to bola najlepšia nemocnica na Ďalekom východe a architekta L. E. Hudeca vyniesla na piedestál uznávaného architekta. Od roku 1991 je budova zapísaná v zozname chránených kultúrnych pamiatok (Shanghai Excellent Historical Building for Preservation).',
        '<strong>Nájomný dom - Estrella Apartments (1925 - 1927)</strong>',
        '148 - 150 No. 1 Ruijin Road, Šanghaj',
        ' Investorom nárožnej budovy bol židovský obchodník s rikšami Alberto Cohen. Polyfunkčný dom akcentuje uličné nárožie architektonicky stvárnenou fasádou v štýle španielskej renesancie s barokovými detailmi. Nárožné skosenie a trojosová kompozícia vyčnievajúcich arkierových balkónov na čelných stranách spolu so zjednocujúcim balkónovým prstencom siedmeho podlažia vytvárajú harmonické pôsobenie. Svetlá omietka je kombinovaná s tmavším sfarbením okenných rámov francúzskych okien, balkónov a grafických ornamentov. Nad obchodnými jednotkami využívaným prvým poschodím sa nachádza pätnásť bytov s dvoj- a trojizbovými jednotkami na podlažie. Na najvyššom poschodí je priestranný apartmán so strešnou terasou. Budova disponuje dobovými technickými novinkami, medzi ktoré patrila mechanická ľadnička, vstavaný šatník a žehliaca doska, kuchyňa s plynovým alebo elektrickým sporákom, kúpeľňa s vaňou, sprchovacím kútom a spaľovňa odpadkov. Od roku 1999 je budova zapísaná v zozname chránených kultúrnych pamiatok (Shanghai Excellent Historical Building for Preservation).',
        '<strong>Banka - J. S. S. Union Building (1926 - 1928)</strong>',
        '261 Middle Sichuan Road, Šanghaj',
        'Hudec získal zákazku v architektonickej súťaži z roku 1926. Bola to zároveň architektova prvá zákazka pre najväčšiu čínsku banku, konzorcium štyroch bánk, klienta J.S.S., a pre ktorého o osem rokov neskôr projektoval Park Hotel.',
        'Budova banky bola postavená na úzkej parcele mestského bloku v gregoriánskom štýle s expresívnym výrazom mramorom obloženého nárožia s vysokým soklom, v kombinácii s pálenou tehlou na vyšších podlažiach. Fasáda je odľahčená veľkými francúzskymi oknami. Vnútorná dispozícia je funkčne rozčlenená s oddelenými prevádzkami zamestnancov a klientov banky. Hlavný nárožný vstup do prevádzky banky bol určený pre zákazníkov. Kancelárie boli situované na druhom poschodí. Od tretieho po siedme podlažie boli priestory na prenájom. Na najvyššom poschodí bola jedáleň a klub pre zamestnancov. V suteréne boli zabudované pokladnice. Interiér je silne dekorovaný mramorovou výzdobou. Po roku 1949 sídlila v budove chemická firma a neskôr bola časť budovy využívaná pre banku Guangdong Development Bank. Dnes je v budove pobočka banky a výstavná miestnosť venovaná architektovi L. E. Hudecovi a histórii spoločnosti.',
        '<strong>Vila lásky - Liu Jisheng´s Residence (1926 - 1931)</strong>',
        '675 Julu Road, Šanghaj',
        'V roku 1924 kúpil šanghajský podnikateľ Liu Jisheng (majiteľ Shanghai Cement Company a Hong Kong Matchstick Factory) lukratívny pozemok, na ktorom dal svojej manželke Chen Bingzhen (Rose) postaviť vilu k 40. výročiu jej narodenia. Etablovaný architekt L. E. Hudec bol priamo oslovený manželkou podnikateľa.',
        'Na rozlohe 1606 m2 navrhol trojpodlažnú vilu symetrickej kompozície v klasicizujúcom štýle v paralele jeho predošlého projektu vily Ho Thung. Priečelie charakterizujú štyri iónske stĺpy prechádzajúce cez dve podlažia predsadenej lodžie. Luxusný charakter sa prejavuje aj v interiéri v špirálovito zatočenom schodisku, vitrážovými oknami, štukovou výzdobou a krištáľovými lustrami. Zábradlie lodžie zdobia manželove iniciálky KSL. Súčasťou zákazky bola záhradná úprava prevedená do posledného detailu (kvetináče, kamenné lavičky, tenisový kurt a centrálna záhradná fontána, ktorú zdobí 1,5 metrová socha Psyché z bieleho mramoru, ktorá bola darom architekta majiteľke vily. V roku 1948 sa manželia presťahovali do Hongkongu. Svoju životnú etapu ukončili v kanadskom Montreale, kde sú obaja pochovaní (Liu – 1962, Rose – 1964). Počas revolučného obdobia bola socha Psyché ukrytá v záhradnom domčeku, ktorý ostal neporušený. Od roku 1952 je budova sídlom asociácie spisovateľov (Shanghai Writer´s Association). Dnes je prostredie vily využívané ako kulisa pre mnohé filmové a seriálové produkcie. Z uličnej strany k vile pribudla prístavba predajne kníh. Od roku 1999 je budova zapísaná v zozname chránených kultúrnych pamiatok.',
        '<strong>Metodistický kostol - Moore Memorial Church (1926 - 1931)</strong>',
        '316 Middle Xizang Road, Šanghaj',
        'Na starších základoch prestavaný metodistický kostol bol iniciovaný americkým podnikateľom Christianom Moorom (J. M. Moor). Železobetónová konštrukcia budovy (Eastern Iron Works Company z Anglicka), ktorá je postavená v historizujúcom neogotickom štýle s vežou vysokou 42,1 m, zostáva v tieni blízkeho Park Hotela, s ktorým zdieľa zeleňou pokryté námestie People\'s Square. K hlavnej lodi kostola, ktorej kapacita je 1200 ľudí, sú po nárožiach pristavané objekty administratívy, vzdelávacieho centra, ktoré svojou kompozíciou vytvorili priestor pre dve vnútorné nádvoria. Fasáda je vytvorená z pohľadovej pálenej tehly, ktorá je akcentovaná kamennými šambránami gotických okien a detailmi nároží.',
        'New German Lutheran Church bol obdobným dielom architekta, ktorý bol v roku 1970 asanovaný. Počas vojnového obdobia (1937 – 1945) bol kostol útočiskom pre utečencov. V roku 1979 bola v budove zriadená škola. V roku 2009 bol kostol zrekonštruovaný. Okrem pravidelných bohoslužieb sa komplex využíva na vzdelávanie, sociálne aktivity a hosťovanie koncertov. Od roku 1991 je budova zapísaná v zozname chránených kultúrnych pamiatok (Shanghai Municipal Relics and Preservation).',
        '<strong>Vila - Sun Ke\'s House (1929 – 1931)</strong>',
        '60 Panyu Road, Šanghaj',
        'Architekt L. E. Hudec navrhol v rezidenčnej štvrti Columbia Circle viacero budov. Pôvodný zámer vlastnej rodinnej vily bol zmenený, vila bola predaná za výhodných podmienok synovi zakladateľa Kuomintangu, Szun Jat-szenovi a jeho žene Lu Muzhen. Majiteľ vily Sun Ke bol v období rokov 1921 – 1926 primátorom Guangzhou a zaslúžil sa o zlepšenie vzdelávacieho systému a verejného poriadku.',
        'Trojpodlažná vila okrovej farby bola navrhnutá v eklektickom štýle so zmesou španielskych a talianskych inšpirácií. Vplyvy architektúry južnej Európy sú viditeľné aj na škridlovej sedlovej streche, špirálových stĺpoch, štukovej výzdobe a detailoch s biomorfnými tvarmi. Vnútorná dispozícia je funkčne rozdelená do deviatich polí obytných miestností. K vile patrí veľká záhrada s rozlohou tri hektáre. Od roku 1950 je budova využívaná na účely Shanghai Biochemical Research Institute.',
        '<strong>Rodinný dom - Hudec\'s Residence (1930)</strong>',
        '129 Panyu Road, Šanghaj',
        'Architekt L. E. Hudec sa s manželkou Gizelou Meyer rozhodli po narodení ich tretieho potomka, dcéry Alessy, postaviť si vlastný rodinný dom. Dom bol postavený na mieste predošlého objektu v blízkosti ďalších architektových projektov, v pokojnej lokalite Columbia Circle.',
        'Trojpodlažný objekt v tudorovskom štýle s priznanou hrazdenou konštrukciou a vysokými štítmi sedlových striech s bridlicovou krytinou zdobia dva dominantné tehlové komíny. Až neskôr bola k objektu pristavaná garáž a pracovňa L. E. Hudeca, z ktorej pochádza najznámejšia fotografia architekta sediaceho za pracovným stolom. Originálny interiér bol navrhnutý do posledného detailu. K domu bola pričlenená veľká južná záhrada. Život rodiny vo vytvorenej oáze pokoja chránil pred nástrahami veľkomesta vysoký betónový múr a mreže na celom objekte. Od roku 1949 bola budova využívaná pre vzdelávacie účely (Tourist Vocational School). Od roku 2005 je zapísaná v zozname chránených kultúrnych pamiatok a v roku 2010 bola rekonštruovaná. Dnes sa v budove obklopenej výškovými budovami nachádza múzeum architekta L. E. Hudeca (Hudec Memorial House).',
        '<strong>Knižné vydavateľstvo - China Baptist Publication Building a Christian Literature Society Building (1930 - 1932)</strong>',
        '209 Yuanmingyuan Road a 128 Huqiu Road, Šanghaj',
        'Reprezentatívny blok dvoch administratívnych budov na sútoku šanghajských riek Suzhou a Huangpu (Yellow river) bol postavený v expresionistickom štýle s prvkami Art Deco. Objekty, ktoré vytvárajú pôdorysný tvar písmena U, majú výraznú tektonickú štruktúru pri použití pohľadových pálených tehiel na fasáde. Budovy majú spoločné požiarne schodisko a kotolňu. Za statiku budov zodpovedala firma Bengt J. Lindskog. Na západnej strane bola navrhnutá budova vydavateľstva Christian Literature Society. V deväťposchodovej budove bolo na prízemí kníhkupectvo, siedme poschodie využívali zamestnanci, na ôsmom poschodí boli kancelárie a na najvyššom boli zasadacie miestnosti. Priestor medzi druhým až siedmym poschodím bol komerčne prenajímaný.',
        'Fasáda inštitúcie sa od vedľajšej budovy líši výraznými kamennými pilastrami na fasáde.',
        'Z východnej strany pozemku bol vstup do spoločnosti China Baptist Publication Building. Budova tiež pod označením Gospel Light Building vznikla z iniciatívy amerického misionára Roberta E. Chambersa. Na poslednom podlaží osemposchodovej budovy, v ktorej sídlila administratíva spoločnosti, mal v období rokov 1932 – 1947 ateliér architekt L. E. Hudec. Od roku 1994 sú obe budovy pamiatkovo chránené. V roku 2002 boli citlivo začlenené do developerského projektu Waitanyuan project.',
        '<strong>Slávne kino - Grand Theatre (1931 - 1933)</strong>',
        '216 West Nanjing Road, Šanghaj',
        'Najimpozantnejšie diela L. E. Hudeca sa nachádzajú na centrálnom šanghajskom námestí People\'s Square, na ktorom sa v čase architektovej tvorby nachádzala dostihová dráha pre kone. Na mieste staršieho kina v úzkej prieluke vyrástlo najznámejšie dielo L. E. Hudeca – Grand Theatre. Projekt inicioval Lu Geng (zakladateľ United Movies Co.). Kino na širokouhlej obrazovke premietalo filmy európskej a americkej kinematografie (20th Century Fox a Metro-Goldwin-Mayer) pre 2200 divákov. V tej dobe to bolo najväčšie kino na Ďalekom východe. Prvým premietaným filmom bol Hell Below.',
        'Architektonická jedinečnosť objektu bola podporená technologickými novinkami, simultánnym prekladom a vysokovýkonnou klimatizáciou. Dokonalé konštrukčné riešenie hlavnej sály širokého premostenia zvonového tvaru vzniklo zo spolupráce architekta s firmou Bengt J. Lindskog Company (statika) a dodávateľom oceľových konštrukcií Eastern Iron Works English. Za zjednocujúcou štýlovou fasádou so svetelnou vežou a výrazným vstupom boli popri hlavnej kinosále v komplexe situované biliardové miestnosti a veľká tančiareň s kaviarňou. Detaily interiéru sú prevedené v štýle Art Deco (zábradlia, stropné osvetlenia) a svoje miesto si našli aj graficky stvárnené monogramy architekta (Hudec\'s codes). V roku 1949 bola inštitúcia premenovaná na Grand Cinema. V roku 2008 bol komplex rekonštruovaný architektom, univerzitným profesorom Zhang Mingom. Priestor hlavnej kinosály sa zmenšil na kapacitu 1300 miest. Vznikli menšie kinosály, reštaurácia a strešná terasa. Je pozoruhodné. že Hudecovo dielo bolo adaptované na multiplexové kino bez zásadného porušenia jeho architektonického výrazu a hodnoty.',
        '<strong>Park Hotel (1931 - 1934)</strong>',
        '170 West Nanjing Road, Šanghaj',
        'Najslávnejšie dielo architekta, Park Hotel, patrilo medzi architektonický a technologický unikát doby. Je v blízkom susedstve Grand Theatre a Moore Memorial Church. Dvadsaťdvaposchodová budova s výškou 83,8 m si do roku 1952 držala výškové prvenstvo na ázijskom kontinente. Do roku 1966 bola najvyššou budovou Číny a do roku 1983 najvyššou budovou Šanghaja.',
        '" ,... je to najvyššia budova, ktorá bola postavená na východnej pologuli, to je z Londýna do Tokia. Je to skutočne chvályhodné." (Q. L. Dao 1934)',
        'Investorom budovy bolo konzorcium štyroch bánk, spoločnosť J. S. S. (Joint Savings Society Bank). Prvé tri nadzemné podlažia vytvárajú vysokú podnož. Sú obložené čiernou leštenou žulou (Shandong Province). Obsahujú vstupné priestory hotela, reštauráciu a samostatnú pobočku banky. Symetrické okenné otvory vyšších podlaží sú orámované tehlovým obkladom. Medzi tretím až štrnástym poschodím sú hotelové izby. Pätnáste až osemnáste poschodie tvoria apartmány, v ktorých boli ubytovaní štátnici a králi. Stupňovité strešné ukončenie bolo inšpirované tvorbou amerických architektov. Mimoriadna pozornosť bola venovaná interiérovému a kúpeľňovému dizajnu. Hotel, ktorý bol otvorený 1. decembra 1934 primátorom Wu Te-chenom, je postavený v geografickom strede Šanghaja, na „Point Zero“. Budova vládnuca nad šanghajským nebom bola symbolom čínskeho kapitalizmu, bohatstva, majetku a svetskej slávy.',
        'Použitím odľahčenej chrómovo-medenej ocele a nabitím 400 kusov 33-metrových borovíc do ílovitého podložia močaristého pozemku sa budove zabezpečila stabilita. Základy budovy tvorili tretinu výšky nadzemnej časti. Vyznačovala sa technickými inováciami doby. Mala najrýchlejšie výťahy na svete (600 stôp za minútu), hydraulicky otváranú strechu tančiarne, umývačky riadu, chladničky, artézsku studňu, vlastnú spaľovňu odpadu, detektory dymu a protipožiarne rozvody.',
        'Rekonštrukciou v roku 1935 pribudla markíza na štrnástom podlaží a pravý vstup do pobočky banky na prízemí. Interiér sa výrazne zmenil aj v roku 1950. Ďalšie rekonštrukcie prebehli v rokoch 1980 a 1997, keď americký dizajnér George Grigorian navrhol Art Deco úpravu interiéru a Christopher Choa navrhol Art Deco úpravu vstupu v roku 2001. Prominentný čínsko-americký architekt I. M. Pei, otec sklenenej pyramídy parížskeho Louvru, tvrdí, že práve Park Hotel ho inšpiroval stať sa architektom. Dnes už Park Hotel v susedstve Shanghai World Financial Centre (s prezývkou „Otvárač na fľaše“) od architektov Kohn Pederson Fox (KPF) a 632-metrovej budovy Shanghai Tower od Arthura Genslera nenájdeme. Je „utopený“ v spleti stovky mrakodrapov, ktoré boli v Šanghaji od tej doby vybudované. Od roku 2006 je budova zapísaná v zozname chránených kultúrnych pamiatok (National Heritage building for preservation).',
        '<strong>Pivovar - Union Brewery Ltd. (1931 - 1936)</strong>',
        '130 Yichang Road, Šanghaj',
        'Do rozsiahleho portfólia architekta patria aj priemyselné budovy. Popri Zhabei Power Station z roku 1930 na rieke Huangpu L. E. Hudec navrhol aj pivovar Union Brewery. Majetok dánskeho podnikateľa Frithjofa Hoehnkeho bol predaný firme Schwarzkopf and Co., ktorá do roku 1935 prevádzkovala pivovar. Neskôr sa stal majiteľom pivovaru Victor Sasson a podnik premenoval na Šanghajský pivovar. Na močaristom podloží postavený komplex budov s rozlohou 28 800 m2 s deväťpodlažnou, najvyššou budovou tej doby vzbudil pozornosť ostatných podnikateľov, ktorí architekta L. E. Hudeca kontaktovali neskôr. Technológiou pivovarníctva sa architekt inšpiroval počas pobytu v Nemecku.',
        'Komplex budov obsahuje samostatnú varňu, budovu plničky fliaš, kancelárie, odbytovú predajňu a objekt s elektrickým generátorom. Varné nádoby so zaťažením 25 t/m2 umiestnené na ílovitom podloží boli pre architekta technologickou výzvou, ktorej sa s úspechom zhostil. Ročná produkcia 2 milióny litrov piva bola atraktívna aj pre tradičnú čínsku čajovú kultúru. V roku 2005 bola časť komplexu zbúraná a zostávajúca časť bola adaptovaná do viacúčelového areálu Mentqing garden. V zachovaných budovách je reštauračné zariadenie a tematická výstavná inštalácia. Od roku 1999 je budova zapísaná v zozname chránených kultúrnych pamiatok (Shanghai Excellent Historical Building for Preservation).',
        '<strong>Nájomný dom - Hubertus Court (1933 - 1936)<strong/>',
        '918 West Yan\'an Road, Šanghaj',
        'Hubertus Court, nazývaný aj Little Park Hotel, je desaťpodlažná budova v západnej časti pôvodného International Settlement (extra settlements). So zdražovaním a zhodnocovaním stavebných parciel sa výšková schéma zástavby začala aplikovať tiež v obytnej architektúre. Hubertus Court bola najvyššou rezidenčnou budovou v Šanghaji. Projekt bol zároveň investičným projektom L. E. Hudeca (The Hubertus Properties Fed. Inc.).',
        'V rokoch 1937 – 1947 býval architekt spolu s manželkou Gizelou a dcérou Alessou na prvom podlaží v priestrannom byte s výhľadom do súkromnej záhrady. Prízemie tvorili vstupné spoločenské priestory s výťahmi a parkoviskom pre dvadsať áut. Štandardné podlažie obsahovalo tri apartmány, dva väčšie po stranách s balkónmi orientovanými na južnú stranu a stredový dvojizbový apartmán. Na najvyšších dvoch podlažiach boli štyri luxusné apartmány s terasami. Na objekte je viditeľný vplyv európskeho funkcionalistického prístupu v obytnej architektúre a jej detailoch. Dielo bolo publikované v architektonických magazínoch v Anglicku, Španielsku a Japonsku. Od roku 1949 bola v budove reštaurácia spoločnosti Dahua, ktorá neskôr adaptovala celý objekt na hotel (Jinjiang Metropolo Hotel Classiq Shanghai Jing’an). Na južnej strane pribudol troj- a na severnej štvorpodlažný objekt. Od roku 1999 je budova zapísaná v zozname chránených kultúrnych pamiatok (Shanghai Excellent Historical Building for Preservation). V roku 2013 bola v objekte otvorená pamätná izba venovaná architektovi L. E. Hudecovi a projektu Hubertus Court.',
        '<strong>Zelený dom - D. V. Woo\'s House (1935 - 1938)</strong>',
        '333 Tongren Road, Šanghaj',
        'Reprezentatívne dielo architekta L. E. Hudeca s prejavom nastupujúcej moderny s využitím dynamického zaoblenia stien. Návrh štvorpodlažnej vily s pivničným priestorom a autom priechodným prízemím funkčne zhodnocuje nárožnú parcelu v susedstve ďalšieho projektu L. E. Hudeca. Vila s rozlohou 1700 m2 bola dokončená v čase vojnou vyostrenej spoločenskej situácie. Rezidenčná vila bola navrhnutá pre obchodníka s farbami, pigmentového magnáta D. V. Woo, ktorý zbohatol práve predajom zelenej farby pre vojenské účely (pomenovanie „Green House“/ „Zelený dom“). Investor kúpil od architekta plány projektu domu z obáv jeho napodobňovania. V auguste 1966 spáchal majiteľ domu s manželkou (z prominentnej rodiny Pei, odkiaľ pochádza americko-čínsky architekt I. M. Pei) samovraždu.',
        'Vila mala ako prvá súkromná rezidencia osobný výťah. K ďalším technickým vymoženostiam patrila klimatizácia a vykurovacie telesá zabudované do stien. Schodisko bolo obložené talianskym mramorom. Nárožná valcová miestnosť s panoramatickým zasklením bola vybavená skleneným strešným svetlíkom. Obytné miestnosti boli orientované do malej záhrady a obslužné priestory do ulice. Vila patrila k najpriestrannejším a najluxusnejším rezidenčným budovám na ázijskom kontinente. V roku 2009 bola vila poškodená prevádzkou nočného klubu. Od roku 2014 je budova súčasťou inštitútu urbanizmu (Shanghai Urban Planning and Design Institution).'
    ]
};

const HudecShanghai4Page = () => {
    return (
        <Blog data={blog} />
    );
};

export default HudecShanghai4Page;